// import React, { useEffect, useState } from "react";
// import { Button, CircularProgress, Grid, Typography } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import Header from "../../Components/Header";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { OC_ID, SITE_URL } from "../../config";

// const Agenda = () => {
//   const navigate = useNavigate();

//   const onclicked = () => {
//     navigate("/information");
//   };

//   let token = localStorage.getItem("token");
//   const [agenda, setAgenda] = useState([]);
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     try {
//       setLoading(true);
//       fetch(
//         `${SITE_URL}/api/version//v2/oc/${OC_ID}/visitant/fetch-paginated-special-events?page=1&responser=true`,
//         {
//           method: "GET",
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       )
//         .then((res) => {
//           setLoading(false);
//           return res.json();
//         })
//         .then((results) => {
//           if (results) {
//             setAgenda(results?.data);
//             setLoading(false);
//           }
//         });
//     } catch (err) {
//       console.log(err);
//       setLoading(false);
//     }
//   }, []);

//   console.log(agenda);
//   return (
//     <Grid container style={{ marginBottom: "60px" }}>
//       <Header />
//       <Grid
//         style={{
//           width: "100%",
//           padding: "10px 15px",
//           display: "flex",
//           justifyContent: "flex-start",
//           alignItems: "flex-start",
//         }}
//       >
//         <Button
//           onClick={onclicked}
//           style={{
//             padding: "10px 10px",
//             textTransform: "none",
//             background: "none",
//             color: "#ffffff",
//             fontSize: "25px",
//             fontWeight: "500",
//             fontFamily: "'Roboto Condensed', sans-serif",
//           }}
//         >
//           <ArrowBackIcon style={{ color: "#000000", marginRight: 5 }} />
//           <b style={{ color: "#000000" }}>Faqs</b>
//         </Button>
//       </Grid>
//       {!loading ? (
//         <Grid
//           style={{
//             maxWidth: "500px",
//             margin: "0px auto",
//             padding: "0 10px",
//           }}
//         ></Grid>
//       ) : (
//         <Grid
//           style={{
//             width: "100%",
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             padding: "0 10px",
//           }}
//         >
//           <CircularProgress />
//         </Grid>
//       )}
//     </Grid>
//   );
// };

// export default Agenda;

// import React, { useEffect, useState } from "react";
// import {
//   Button,
//   CircularProgress,
//   Grid,
//   Typography,
//   Card,
//   CardMedia,
//   CardContent,
// } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import Header from "../../Components/Header";
// import { OC_ID, SITE_URL } from "../../config";

// const Agenda = () => {
//   const navigate = useNavigate();

//   const [agenda, setAgenda] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [activeExInfo, setActiveExInfo] = useState(null); // Manage active ex_info

//   const token = localStorage.getItem("token");

//   useEffect(() => {
//     const fetchAgenda = async () => {
//       try {
//         setLoading(true);
//         const response = await fetch(
//           `${SITE_URL}/api/version//v2/oc/${OC_ID}/visitant/fetch-paginated-special-events?page=1&responser=true`,
//           {
//             method: "GET",
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         const results = await response.json();
//         if (results?.data) {
//           setAgenda(results.data);
//           setActiveExInfo(results.data[0]?.ex_info); // Set the first ex_info as active by default
//         }
//       } catch (err) {
//         console.error("Error fetching agenda:", err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchAgenda();
//   }, [token]);

//   const handleBackClick = () => {
//     navigate("/information");
//   };

//   // Get unique ex_info values for the tabs
//   const uniqueExInfo = [...new Set(agenda.map((item) => item.ex_info))];

//   return (
//     <Grid container style={{ marginBottom: "60px" }}>
//       <Header />

//       <Grid
//         style={{
//           width: "100%",
//           padding: "10px 15px",
//           display: "flex",
//           justifyContent: "flex-start",
//           alignItems: "flex-start",
//         }}
//       >
//         <Button
//           onClick={handleBackClick}
//           style={{
//             padding: "10px 10px",
//             textTransform: "none",
//             background: "none",
//             color: "#ffffff",
//             fontSize: "25px",
//             fontWeight: "500",
//             fontFamily: "'Roboto Condensed', sans-serif",
//           }}
//         >
//           <ArrowBackIcon style={{ color: "#000000", marginRight: 5 }} />
//           <b style={{ color: "#000000" }}>Faqs</b>
//         </Button>
//       </Grid>

//       {loading ? (
//         <Grid
//           style={{
//             width: "100%",
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             padding: "0 10px",
//           }}
//         >
//           <CircularProgress />
//         </Grid>
//       ) : (
//         <>
//           {/* Horizontal Scroll Tabs */}
//           <Grid
//             container
//             style={{
//               overflowX: "auto",
//               whiteSpace: "nowrap",
//               padding: "10px 0",
//               background: "#f5f5f5",
//             }}
//           >
//             {uniqueExInfo.map((exInfo) => (
//               <Button
//                 key={exInfo}
//                 onClick={() => setActiveExInfo(exInfo)}
//                 style={{
//                   padding: "10px 15px",
//                   margin: "0 5px",
//                   textTransform: "none",
//                   background: activeExInfo === exInfo ? "#000" : "#fff",
//                   color: activeExInfo === exInfo ? "#fff" : "#000",
//                   border: "1px solid #ddd",
//                 }}
//               >
//                 {exInfo || "Unknown"}
//               </Button>
//             ))}
//           </Grid>

//           {/* Active Tab Content */}
//           <Grid
//             container
//             spacing={2}
//             style={{
//               maxWidth: "800px",
//               margin: "20px auto",
//               padding: "0 10px",
//             }}
//           >
//             {agenda
//               .filter((event) => event.ex_info === activeExInfo)
//               .map((event) => (
//                 <Grid item xs={12} key={event.id}>
//                   <Card style={{ marginBottom: "15px" }}>
//                     {event.card_image_url && (
//                       <CardMedia
//                         component="img"
//                         height="150"
//                         image={event.card_image_url}
//                         alt={event.title}
//                       />
//                     )}
//                     <CardContent>
//                       <Typography variant="h6" gutterBottom>
//                         {event.title || "Untitled Event"}
//                       </Typography>
//                       <Typography variant="body2" color="textSecondary">
//                         {event.description || "No description available."}
//                       </Typography>
//                       <Typography variant="body2" style={{ marginTop: "8px" }}>
//                         <strong>Venue:</strong> {event.venue || "N/A"}
//                       </Typography>
//                       <Typography variant="body2">
//                         <strong>Start:</strong>{" "}
//                         {new Date(event.start_at).toLocaleString()}
//                       </Typography>
//                       <Typography variant="body2">
//                         <strong>End:</strong>{" "}
//                         {new Date(event.end_at).toLocaleString()}
//                       </Typography>
//                     </CardContent>
//                   </Card>
//                 </Grid>
//               ))}
//           </Grid>
//         </>
//       )}
//     </Grid>
//   );
// };

// export default Agenda;

import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Header from "../../Components/Header";
import { OC_ID, SITE_URL } from "../../config";

const formatTime = (date) => {
  const parsedDate = new Date(date);

  const hours = parsedDate.getHours();

  const finalHour = hours > 12 ? hours - 12 : hours;
  const finalMinute = parsedDate.getMinutes();

  const displayHour = finalHour < 10 ? `0${finalHour}` : finalHour;
  const displayMinute = finalMinute < 10 ? `0${finalMinute}` : finalMinute;

  return `${displayHour}:${displayMinute} ${hours >= 12 ? "PM" : "AM"}`;
};

const Agenda = () => {
  const navigate = useNavigate();

  const [agenda, setAgenda] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeExInfo, setActiveExInfo] = useState(null); // Manage active tab

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchAgenda = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${SITE_URL}/api/version//v2/oc/${OC_ID}/visitant/fetch-paginated-special-events?page=1&responser=true`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const results = await response.json();
        if (results?.data) {
          setAgenda(results.data);

          // Find the first non-null ex_info or default to "Uncategorized"
          const firstValidExInfo =
            results.data.find((event) => event.ex_info)?.ex_info ||
            "Uncategorized";
          setActiveExInfo(firstValidExInfo);
        }
      } catch (err) {
        console.error("Error fetching agenda:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchAgenda();
  }, [token]);

  const handleBackClick = () => {
    navigate("/");
  };

  // Get unique ex_info values excluding null
  const uniqueExInfo = [
    ...new Set(
      agenda.filter((event) => event.ex_info).map((event) => event.ex_info)
    ),
  ];

  return (
    <Grid container style={{ marginBottom: "60px" }}>
      <Header />

      <Grid
        style={{
          width: "100%",
          padding: "10px 15px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={handleBackClick}
          style={{
            padding: "10px 10px",
            textTransform: "none",
            background: "none",
            color: "#ffffff",
            fontSize: "25px",
            fontWeight: "500",
            fontFamily: "'Roboto Condensed', sans-serif",
          }}
        >
          <ArrowBackIcon style={{ color: "#000000", marginRight: 5 }} />
          <b style={{ color: "#000000" }}>Agenda</b>
        </Button>
      </Grid>

      <Grid
        style={{ maxWidth: "500px", margin: "0px auto", padding: "0px 15px" }}
      >
        {loading ? (
          <Grid
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "0 10px",
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            {/* Horizontal Scroll Tabs */}
            <Grid
              container
              style={{
                overflowX: "auto",
                whiteSpace: "nowrap",
                padding: "10px 0",
                background: "rgb(80,112,143)",
              }}
            >
              {uniqueExInfo.map((info) => (
                <Button
                  key={info}
                  onClick={() => setActiveExInfo(info)}
                  style={{
                    padding: "10px 15px",
                    margin: "0 5px",
                    textTransform: "none",
                    background:
                      activeExInfo === info ? "rgb(0,96,170)" : "transparent",
                    color: activeExInfo === info ? "#fff" : "#ffffff",
                    border: "1px solid #ddd",
                  }}
                >
                  {info}
                </Button>
              ))}
            </Grid>

            {/* Active Tab Content */}
            <Grid
              container
              spacing={2}
              style={{
                maxWidth: "800px",
                margin: "20px auto",
                padding: "0 10px",
              }}
            >
              {agenda
                .filter(
                  (event) => (event.ex_info || "Uncategorized") === activeExInfo
                )
                .map((event) => (
                  <Grid
                    style={{
                      width: "100%",
                      marginTop: 5,
                      marginBottom: 15,
                      overflow: "hidden",
                    }}
                  >
                    {event?.start_at && (
                      <Grid
                        style={{
                          borderBottomWidth: 3,
                          marginBottom: 5,
                          borderColor: "rgb(0,96,170)",
                        }}
                      >
                        <Grid style={{ flexDirection: "row" }}>
                          <Typography
                            style={{
                              fontWeight: "900",
                              color: "#000000",
                              fontSize: 14,
                            }}
                          >
                            {formatTime(event?.start_at)} -{" "}
                            {formatTime(event?.end_at)}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    <Typography
                      style={{
                        color: "#000000",
                        fontSize: 16,
                        fontWeight: "bold",
                      }}
                    >
                      {event.title}
                    </Typography>
                    {event?.venue && (
                      <Typography
                        style={{
                          color: "#000000",
                          fontSize: 14,
                          fontWeight: "700",
                          marginBottom: 5,
                        }}
                      >
                        {event?.venue}
                      </Typography>
                    )}
                    {event?.dress_code && (
                      <Typography
                        style={{
                          color: "#000000",
                          fontSize: 14,
                          fontWeight: "700",
                          lineHeight: 18,
                          marginBottom: 5,
                        }}
                      >
                        {event?.dress_code}
                      </Typography>
                    )}
                    {!!event?.card_image_url && (
                      <Grid
                        style={{
                          width: 100,
                          marginBottom: 3,
                        }}
                      >
                        <img
                          source={{ uri: SITE_URL + event.card_image_url }}
                          style={{
                            flex: 1,
                            height: 60,
                            width: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </Grid>
                    )}

                    {/* {event?.description && (
                      <Typography
                        style={{
                          color: "#000000",
                          marginBottom: 2,
                          fontSize: 14,
                          fontWeight: "700",
                        }}
                      >
                        {event?.description}
                      </Typography>
                    )} */}
                    {event?.description && (
                      <Typography
                        style={{
                          color: "#000000",
                          marginBottom: 2,
                          fontSize: 14,
                          fontWeight: "700",
                          whiteSpace: "pre-wrap", // Add this if you want to preserve whitespace and line breaks
                        }}
                      >
                        {event.description.split("\n").map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </Typography>
                    )}
                  </Grid>
                ))}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Agenda;
