import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Button } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";

import Header from "../../Components/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DetailsModal from "./detailsModal";
import "./style.css";

const Contests = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState({ title: "", desOne: "", desTwo: "" });
  const handleOpen = (title, desOne, desTwo) => {
    setOpen(true);
    setDetails({ title: title, desOne: desOne, desTwo: desTwo });
  };
  const handleClose = () => {
    setOpen(false);
    setDetails({ title: "", desOne: "", desTwo: "" });
  };

  const onclicked = () => {
    navigate("/");
  };

  return (
    <>
      <Grid container style={{ marginBottom: "60px" }}>
        <Header />
        <Grid
          style={{
            width: "100%",
            padding: "10px 15px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Button
            onClick={onclicked}
            style={{
              padding: "10px 10px 10px 5px",
              textTransform: "none",
              background: "none",
              color: "#ffffff",
              fontSize: "25px",
              fontWeight: "500",
              fontFamily: "'Roboto Condensed', sans-serif",
            }}
          >
            <ArrowBackIcon style={{ color: "#ffffff", marginRight: 10 }} />
            <b style={{ color: "#ffffff" }}>Contests</b>
          </Button>
        </Grid>
        <Grid
          style={{
            width: "100%",
            maxWidth: "500px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 50px",
            margin: "auto",
          }}
        >
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            {/* <Button
              sx={{
                width: "130px",
                height: "130px",
                padding: "8px",
                borderRadius: "50%",
                // backgroundColor: "transparent",
                color: "green",
                fontSize: "12px",
                fontWeight: "800",
                lineHeight: "18px",
                // "&:hover": {
                //   //you want this to be the same as the backgroundColor above
                //   backgroundColor: "transparent",
                // },
              }}
              onClick={() =>
                handleOpen(
                  "Fastest Finger First",
                  "Log in to our microsite on Dec 11th and be one of the fastest 15 fingers to win a reward!",
                  ""
                )
              }
              className="contextbtn"
            >
              Fastest Finger First
            </Button> */}
            <Grid className="contextbtn">
              <Button
                sx={{
                  width: "120",
                  height: "40",
                  padding: "8px",
                  borderRadius: "20px",
                  color: "#000000",
                  fontSize: "10px",
                  fontWeight: "800",
                  lineHeight: "14px",
                  backgroundColor: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#ffffff", // Keeps background unchanged on hover
                  },
                }}
                onClick={() =>
                  handleOpen(
                    "Fastest Finger First",
                    "Log in to our microsite on Dec 11th and be one of the fastest 15 fingers to win a reward!",
                    ""
                  )
                }
              >
                Fastest Finger First
              </Button>
            </Grid>
          </Grid>
          {/* <Grid
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              sx={{
                width: "130px",
                height: "130px",
                padding: "8px",
                borderRadius: "50%",
                backgroundColor: "#fff",
                color: "green",
                fontSize: "12px",
                fontWeight: "800",
                lineHeight: "18px",
                "&:hover": {
                  //you want this to be the same as the backgroundColor above
                  backgroundColor: "#FFF",
                },
              }}
              onClick={() =>
                handleOpen(
                  "Tagline",
                  "Invite employees to come up with a creative tagline for ‘Pluxee Pinnacle’ that reflects the spirit of the event.",
                  "Winning tagline becomes part of the event branding!"
                )
              }
            >
              Tagline
            </Button>
          </Grid> */}
          {/* <Grid
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{
                width: "130px",
                height: "130px",
                padding: "8px",
                borderRadius: "50%",
                backgroundColor: "#fff",
                color: "green",
                fontSize: "12px",
                fontWeight: "800",
                lineHeight: "18px",
                "&:hover": {
                  //you want this to be the same as the backgroundColor above
                  backgroundColor: "#FFF",
                },
              }}
              onClick={() =>
                handleOpen(
                  "Scavenger Hunt Challenge",
                  "Hide event-related clues across the microsite, encouraging employees to explore and solve puzzles.",
                  "The fastest solvers receive a special shoutout and prize."
                )
              }
            >
              Scavenger Hunt Challenge
            </Button>
          </Grid>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Button
              sx={{
                width: "130px",
                height: "130px",
                padding: "8px",
                borderRadius: "50%",
                backgroundColor: "#fff",
                color: "green",
                fontSize: "12px",
                fontWeight: "800",
                lineHeight: "18px",
                "&:hover": {
                  //you want this to be the same as the backgroundColor above
                  backgroundColor: "#FFF",
                },
              }}
              onClick={() =>
                handleOpen("Xmas Family Photo", "No Description", "")
              }
            >
              Xmas Family Photo
            </Button>
          </Grid>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{
                width: "130px",
                height: "130px",
                padding: "8px",
                borderRadius: "50%",
                backgroundColor: "#fff",
                color: "green",
                fontSize: "12px",
                fontWeight: "800",
                lineHeight: "18px",
                "&:hover": {
                  //you want this to be the same as the backgroundColor above
                  backgroundColor: "#FFF",
                },
              }}
              onClick={() =>
                handleOpen(
                  "Photo Caption",
                  "Post intriguing pictures related to Kolkata or office culture, and ask employees to write creative captions.",
                  "Funniest and most unique captions win prizes."
                )
              }
            >
              Photo Caption
            </Button>
          </Grid>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Button
              sx={{
                width: "130px",
                height: "130px",
                padding: "8px",
                borderRadius: "50%",
                backgroundColor: "#fff",
                color: "green",
                fontSize: "12px",
                fontWeight: "800",
                lineHeight: "18px",
                "&:hover": {
                  //you want this to be the same as the backgroundColor above
                  backgroundColor: "#FFF",
                },
              }}
              onClick={() =>
                handleOpen("Social Media Buzz", "No Description", "")
              }
            >
              Social Media Buzz
            </Button>
          </Grid> */}
        </Grid>
      </Grid>
      <DetailsModal
        isModalOpen={open}
        handleClose={handleClose}
        title={details.title}
        desOne={details.desOne}
        desTwo={details.desTwo}
      />
    </>
  );
};

export default Contests;
