import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrolToTop from "./Components/ScrolToTop";
import Login from "./Pages/Login/Login";
import Home from "./Pages/Home";
import TriviaQuiz from "./Pages/Triviaquiz";
import Livepolls from "./Pages/Livepolls";
import Contest from "./Pages/Contest";
import Highlights from "./Pages/Highlights";
import Leaderboard from "./Pages/Leaderboards";
import FAQs from "./Pages/FAQs";
import DODont from "./Pages/FAQs/DoDonts";
import Agenda from "./Pages/Agenda";
import Timeline from "./Pages/Timeline";
import Notification from "./Pages/Notification";
import Profile from "./Pages/Profile";
import HelpDesk from "./Pages/HelpDesk/index";
import Survey from "./Pages/Survey";
// import Networking from "./Pages/Networking";
import Networking from "./Pages/Vibe";
import Information from "./Pages/Information";
// import { useState } from "react";
// import PageNotFound from "./Components/PageNotFound";
// import Footer from "./Components/Footer";
import ProtectedRout from "./Components/ProtectedRout";
function App() {
  // let sessionStorageToken = localStorage.getItem("token");
  // let sessionStorageCurrentUserId = localStorage.getItem("currentUserId");
  // let sessionStorageCurrentUserName = localStorage.getItem("currentUserName");
  // let sessionStorageCurrentUserAvatarUrl = localStorage.getItem(
  //   "currentUserAvatarUrl"
  // );

  return (
    <div className="App">
      <BrowserRouter>
        <ScrolToTop />
        {/* {sessionStorageToken ? (
          <> */}
        <Routes>
          <Route exact path="/" element={<ProtectedRout Component={Home} />} />
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/triviaquiz"
            element={<ProtectedRout Component={TriviaQuiz} />}
          />
          <Route
            exact
            path="/livepolls"
            element={<ProtectedRout Component={Livepolls} />}
          />
          <Route
            exact
            path="/helpdesk"
            element={<ProtectedRout Component={HelpDesk} />}
          />
          <Route
            exact
            path="/highlights"
            element={<ProtectedRout Component={Highlights} />}
          />
          <Route
            exact
            path="/faqs"
            element={<ProtectedRout Component={FAQs} />}
          />
          <Route
            exact
            path="/doanddonts"
            element={<ProtectedRout Component={DODont} />}
          />
          <Route
            exact
            path="/agenda"
            element={<ProtectedRout Component={Agenda} />}
          />
          <Route
            exact
            path="/timeline"
            element={<ProtectedRout Component={Timeline} />}
          />
          <Route
            exact
            path="/notification"
            element={<ProtectedRout Component={Notification} />}
          />
          <Route
            exact
            path="/profile"
            element={<ProtectedRout Component={Profile} />}
          />
          <Route
            exact
            path="/survey"
            element={<ProtectedRout Component={Survey} />}
          />
          <Route
            exact
            path="/networking"
            element={<ProtectedRout Component={Networking} />}
          />
          <Route
            exact
            path="/contests"
            element={<ProtectedRout Component={Contest} />}
          />
          <Route
            exact
            path="/leaderboard"
            element={<ProtectedRout Component={Leaderboard} />}
          />
          <Route
            exact
            path="/information"
            element={<ProtectedRout Component={Information} />}
          />
          {/* <Route
            exact
            path="/networking"
            element={
              <ProtectedRout element={<EmptyScreen title="Networking" />} />
            }
          />
          <Route
            exact
            path="/contests"
            element={
              <ProtectedRout element={<EmptyScreen title="Contests" />} />
            }
          />
          <Route
            exact
            path="/leaderboard"
            element={
              <ProtectedRout element={<EmptyScreen title="Leaderboard" />} />
            }
          /> */}
          {/* <Route path='*' element={<PageNotFound />} /> */}
        </Routes>
        {/* </>
        ) : (
          <Routes>
            <Route exact path="/login" element={<Login />} /> */}
        {/* <Route path='*' element={<PageNotFound />} /> */}
        {/* </Routes>
        )} */}
      </BrowserRouter>
    </div>
  );
}

export default App;
