import "../ComponentStyles/footer.css";
import { Grid, Typography } from "@mui/material";
import React from "react";
import FHOMEIMG from "../Assets/pluxeenew/home.png";
import FAGENDAIMG from "../Assets/pluxeenew/leaderboard.png";
import FTIMELINEIMG from "../Assets/pluxeenew/timeline.png";
import FNOTIFICATIONIMG from "../Assets/pluxeenew/noti.png";
import FPROFILEIMG from "../Assets/pluxeenew/profile.png";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <Grid
      container
      style={{
        width: "100%",
        display: "grid",
        placeItems: "center",
        marginTop: "auto",
        position: "fixed",
        height: "60px",
        bottom: "0",
      }}
    >
      <div className="footer_rout_container">
        <NavLink
          className="footnavbar"
          to="/"
          style={({ isActive }) =>
            isActive
              ? { backgroundColor: "rgb(0,96,170)", padding: 6 }
              : { backgroundColor: "transparent" }
          }
        >
          <img
            src={FHOMEIMG}
            alt="TQUIZIMG"
            style={{ width: 18, height: 25, objectFit: "contain" }}
          />
          <Typography
            variant="button"
            className="footer_topogpy"
            style={{
              fontFamily: '"Roboto Condensed", sans-serif',
              fontSize: "10px",
            }}
          >
            Home
          </Typography>
        </NavLink>

        <NavLink
          className="footnavbar"
          to="/networking"
          style={({ isActive }) =>
            isActive
              ? { backgroundColor: "rgb(0,96,170)", padding: 6 }
              : { backgroundColor: "transparent" }
          }
        >
          <img
            src={FAGENDAIMG}
            alt="POLLSIMG"
            style={{ width: 18, height: 25, objectFit: "contain" }}
          />
          <Typography
            variant="button"
            className="footer_topogpy"
            style={{
              fontFamily: '"Roboto Condensed", sans-serif',
              fontSize: "10px",
            }}
          >
            Networking
          </Typography>
        </NavLink>

        <NavLink
          className="footnavbar"
          to="/timeline"
          style={({ isActive }) =>
            isActive
              ? { backgroundColor: "rgb(0,96,170)", padding: 6 }
              : { backgroundColor: "transparent" }
          }
        >
          <img
            src={FTIMELINEIMG}
            alt="CONTESTSIMG"
            style={{ width: 18, height: 25, objectFit: "contain" }}
          />
          <Typography
            variant="button"
            className="footer_topogpy"
            style={{
              fontFamily: '"Roboto Condensed", sans-serif',
              fontSize: "10px",
            }}
          >
            Timeline
          </Typography>
        </NavLink>

        <NavLink
          className="footnavbar"
          to="/notification"
          style={({ isActive }) =>
            isActive
              ? { backgroundColor: "rgb(0,96,170)", padding: 6 }
              : { backgroundColor: "transparent" }
          }
        >
          <img
            src={FNOTIFICATIONIMG}
            alt="HIGHLIGHTSIMG"
            style={{ width: 18, height: 25, objectFit: "contain" }}
          />
          <Typography
            variant="button"
            className="footer_topogpy"
            style={{
              fontFamily: '"Roboto Condensed", sans-serif',
              fontSize: "10px",
            }}
          >
            Notification
          </Typography>
        </NavLink>

        <NavLink
          className="footnavbar"
          to="/profile"
          style={({ isActive }) =>
            isActive
              ? { backgroundColor: "rgb(0,96,170)", padding: 6 }
              : { backgroundColor: "transparent" }
          }
        >
          <img
            src={FPROFILEIMG}
            alt="LEADERBOARDIMG"
            style={{ width: 18, height: 25, objectFit: "contain" }}
          />
          <Typography
            variant="button"
            className="footer_topogpy"
            style={{
              fontFamily: '"Roboto Condensed", sans-serif',
              fontSize: "10px",
            }}
          >
            Profile
          </Typography>
        </NavLink>
      </div>
    </Grid>
  );
};

export default Footer;
