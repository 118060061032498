import { CircularProgress, Grid, Typography } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DefaultUserImage from "../../Assets/default_profile_pic.png";
import ChatMessage from "./components/ChatMessage";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import SelectFile from "./components/SelectFile";
import createCable from "../../Services/ActionCableService";
import { ScrollToLatestButton } from "./components/ScrollToLatestButton";
import { OC_ID, SITE_URL } from "../../config";

const MessageModel = ({
  chatroomId,
  otherUsername,
  otherUserUrl,
  onModalClosed,
  token,
  otherUserid,
  isConversationAccepted,
  conversationCreatedUserId,
}) => {
  const messagecontainer = useRef(null);
  let currentUserId = localStorage.getItem("currentUserId");
  const [messages, setMessages] = useState({
    data: [],
    links: {},
  });
  const [isActiveSelectIcon, setIsActiveSelectIcon] = useState(false);
  const [file, setFile] = useState(null);
  const [messageText, setMessageText] = useState("");
  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [scrollData, setScrollData] = useState({
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);

  const [isConversatAccepted, setIsConversatAccepted] = useState(
    isConversationAccepted
  );
  const fetchChatroomMessage = async (page) => {
    try {
      // Store the current scroll position and height before loading new messages
      const { scrollTop, scrollHeight } = messagecontainer.current;

      const response = await fetch(
        `${SITE_URL}/api/version/v2/oc/${OC_ID}/visitant/chatroom/messaging-rooms/${chatroomId}/flag-current-user-messages-as-read?page=${page}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        const result = await response.json();
        if (result?.data?.length) {
          const { data, included, links } = result;

          // Reverse the data for the current page
          let NewData = data.reverse();

          // Combine with the previous messages and ensure no duplicates
          let CombinedData = NewData?.concat(messages?.data).filter(
            (obj, index, array) =>
              array.findIndex((el) => el.id === obj.id) === index
          );

          let FilterData =
            CombinedData &&
            CombinedData.map((el) => ({
              ...el,
              user: included?.find(
                (user) => user.id === el?.relationships?.user?.data?.id
              ),
            }));

          // Set the new messages and links in the state
          setMessages((prev) => ({
            data: [...FilterData], // This ensures all data is in reverse format
            links,
          }));

          // Restore the scroll position after new messages are added
          if (page !== 1) {
            setTimeout(() => {
              const newScrollHeight = messagecontainer.current.scrollHeight;
              messagecontainer.current.scrollTop =
                newScrollHeight - scrollHeight + scrollTop;
            }, 100); // Small timeout to allow new messages to render
          }
        }
      } else {
        setMessages({ data: [], links: {} });
      }
    } catch (error) {
      setMessages({ data: [], links: {} });
      console.error(error);
    }
  };

  useEffect(() => {
    fetchChatroomMessage();
    chatroomMessage();
    return () => {
      disconnectChannel();
    };
  }, [chatroomId]);

  const chatroomMessage = async () => {
    const CableApp = await createCable();
    const subscription = CableApp.cable.subscriptions.create(
      { channel: "MessagesChannel", chatroom_id: chatroomId },
      {
        connected() {
          console.log("Connected to messages channel");
        },
        disconnected() {
          console.log("Disconnected from messages channel");
        },
        received: (data) => {
          if (Object.keys(data).length > 0) {
            fetchChatroomMessage();
          }
        },
      }
    );

    if (subscription) {
      setSubscription(subscription);
    }
  };

  const disconnectChannel = () => {
    if (subscription) {
      subscription.unsubscribe();
      setSubscription(null);
    }
  };

  const handleSelectIcon = () => {
    setIsActiveSelectIcon(!isActiveSelectIcon);
  };

  const getNextPage = (str) => {
    const str1 = str.split("=");
    const res = str1[1].split("&");
    return parseInt(res[0], 10);
  };

  const handleLoadMore = async () => {
    if (messages?.links?.next != null) {
      const newPage = getNextPage(messages?.links?.next);
      setCurrentPage(newPage);
      await fetchChatroomMessage(newPage);
    }
  };

  const onScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = messagecontainer.current;
    setScrollData({
      scrollTop,
      scrollHeight,
      clientHeight,
    });

    // If we're near the top of the scroll container and there's more data to load
    if (scrollTop < 50 && messages?.links?.next) {
      handleLoadMore();
    }
  }, [messages?.links?.next]);

  const debounce = (func, time = 500) => {
    let timer;
    return (...rest) => {
      timer && clearTimeout(timer);
      timer = setTimeout(() => {
        func(...rest);
      }, time);
    };
  };

  const debouncedOnScroll = useMemo(() => {
    return debounce(onScroll, 200); // Adjust debounce time as needed
  }, [onScroll]);

  const scrollToBottom = (behavior = "smooth", forceScroll = true) => {
    if (forceScroll) {
      setTimeout(() => {
        messagecontainer.current?.scroll({
          top: messagecontainer.current.scrollHeight,
          behavior,
        });
      }, 100);
    }
  };

  useEffect(() => {
    // If the user has scrolled and the scroll position is far from the bottom, don't scroll automatically
    if (
      scrollData.scrollTop + scrollData.clientHeight >=
      scrollData.scrollHeight - 10
    ) {
      scrollToBottom("smooth", true); // Force scroll to bottom when new message is sent or loaded
    }
  }, [messages]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("chatroom_message[other_user_id]", otherUserid);
      if (messageText != "") {
        formData.append("chatroom_message[message_content]", messageText);
      }
      if (file) {
        formData.append("chatroom_message[message_media]", file);
      }

      const response = await fetch(
        `${SITE_URL}/api/version/v2/oc/${OC_ID}/visitant/chatroom/room-messages`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status == 200 || response.status == 201) {
        setLoading(false);
        setMessageText("");
        setFile(null);
        setIsActiveSelectIcon(false);
        const result = await response.json();
        const { data, included } = result;
        let FilterData = data && {
          ...data,
          user: included?.find(
            (user) => user.id == data?.relationships?.user?.data?.id
          ),
        };
        setMessages((pre) => ({
          data: [...pre.data, FilterData],
          links: { ...pre.links },
        }));
        scrollToBottom();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleMediaLoaded = () => {
    scrollToBottom("smooth", true); // Scroll to bottom when media is loaded
  };

  const showScrollToBottom =
    scrollData.scrollHeight - scrollData.scrollTop - scrollData.clientHeight >
    10;

  const handleUpdateChatroom = async () => {
    const formData = new FormData();
    if (!isConversationAccepted) {
      formData.append("chatroom[is_conversation_accepted]", "true");
    }
    const response = await fetch(
      `${SITE_URL}/api/version/v2/oc/${OC_ID}/visitant/chatroom/messaging-rooms/${chatroomId}/agree-to-chatroom-conversation`,
      {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status == 200 || response.status == 201) {
      setIsConversatAccepted(true);
    }
  };

  return (
    <Grid
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        border: "2px solid rgb(0,96,170)",
        background: "rgb(0,96,170)",
        borderRadius: "5px",
      }}
    >
      <Grid
        style={{
          width: "100%",
          height: 40,
          paddingLeft: 8,
          paddingRight: 8,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "sticky",
          top: 0,
          left: 0,
          right: 0,
          background: "#ffffff",
          borderBottom: "2px solid #000000",
        }}
      >
        <ArrowBackIcon
          onClick={() => onModalClosed()}
          style={{ fontSize: 20, color: "#000000" }}
        />
        {otherUserUrl ? (
          <Grid
            style={{
              width: "30px",
              height: "30px",
              borderRadius: 15,
              overflow: "hidden",
              marginRight: 10,
              // marginTop: 20,
            }}
          >
            <img
              src={`${SITE_URL}${otherUserUrl}`}
              style={{ width: "100%", height: "100%" }}
              alt="Null"
            />
          </Grid>
        ) : (
          <Grid
            style={{
              width: "30px",
              height: "30px",
              borderRadius: 15,
              overflow: "hidden",
              marginRight: 10,
              // marginTop: 20,
            }}
          >
            <img
              src={DefaultUserImage}
              alt="Null"
              style={{ width: "100%", height: "100%" }}
            />
          </Grid>
        )}
        <button
          style={{
            color: "#000000",
            fontWeight: "700",
            border: "none",
            background: "transparent",
          }}
        >
          {otherUsername}
        </button>
      </Grid>
      <Grid
        ref={messagecontainer}
        onScroll={debouncedOnScroll}
        style={{
          width: "100%",
          height: "60vh",
          overflow: "auto",
          paddingBottom: 60,
        }}
      >
        {messages &&
          messages?.data?.length > 0 &&
          messages?.data?.map((item) => (
            <ChatMessage
              profileImage={item?.user?.attributes?.avatarUrl}
              postedBy={item?.user?.attributes?.name}
              currentUserId={currentUserId}
              createdBy={item?.user}
              message={item?.attributes?.messageContent}
              id={item.id}
              postedOn={item?.attributes?.createdAt}
              messageMediaUrl={item?.attributes?.messageMediaUrl}
              messageMediaThumbUrl={item?.attributes?.messageMediaThumbUrl}
              isRead={item?.attributes?.isRead}
              onMediaLoaded={handleMediaLoaded}
              page={currentPage}
            />
          ))}
        {showScrollToBottom && (
          <ScrollToLatestButton onClick={() => scrollToBottom("instant")} />
        )}
      </Grid>
      {isConversatAccepted ? (
        <Grid
          style={{
            width: "100%",
            height: 50,
            background: "#ffffff",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: 15,
            paddingRight: 15,
          }}
        >
          <span
            style={{
              background: "rgb(0,96,170)",
              padding: 6,
              width: "35px",
              height: "35px",
              borderRadius: 17.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 10,
              cursor: "pointer",
            }}
          >
            {!isActiveSelectIcon ? (
              <AttachFileIcon
                style={{ fontSize: "24px", color: "#ffffff" }}
                onClick={() => handleSelectIcon()}
              />
            ) : (
              <CloseIcon
                style={{ fontSize: "24px", color: "#ffffff" }}
                onClick={() => handleSelectIcon()}
              />
            )}
          </span>
          <input
            type="text"
            placeholder="Message"
            style={{
              flex: 1,
              height: 35,
              borderRadius: 25,
              padding: 5,
              marginRight: 10,
              border: "1px solid rgb(0,96,170)",
              outline: "none",
            }}
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          />
          {file || messageText != "" ? (
            loading ? (
              <CircularProgress />
            ) : (
              <SendIcon
                style={{
                  background: "rgb(0,96,170)",
                  color: "#ffffff",
                  padding: 5,
                  borderRadius: 25,
                  fontSize: 35,
                  cursor: "pointer",
                }}
                onClick={handleSubmit}
              />
            )
          ) : (
            <SendIcon
              style={{
                background: "rgb(0,96,170)",
                color: "#ffffff",
                padding: 5,
                borderRadius: 25,
                fontSize: 35,
                cursor: "not-allowed",
                opacity: 0.5,
              }}
            />
          )}
        </Grid>
      ) : !isConversationAccepted &&
        currentUserId == conversationCreatedUserId ? (
        <Grid
          style={{
            width: "100%",
            height: 80,
            background: "#ffffff",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: 15,
            paddingRight: 15,
            borderTop: "2px solid #000000",
          }}
        >
          <Typography variant="caption" style={{ textAlign: "center" }}>
            You can continue once the receiver accepts your chat request.
          </Typography>
        </Grid>
      ) : (
        <Grid
          style={{
            width: "100%",
            height: 80,
            background: "#ffffff",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: 15,
            paddingRight: 15,
            borderTop: "2px solid #000000",
          }}
        >
          <Typography variant="caption" style={{ lineHeight: "17px" }}>
            {" "}
            To continue the conversation, please accept this chat request with a
            'Accept' Button bellow.
          </Typography>
          <button
            onClick={() => handleUpdateChatroom()}
            style={{
              borderRadius: 5,
              background: "rgb(0,96,170)",
              padding: "6px 8px",
              color: "#ffffff",
              border: "none",
              cursor: "pointer",
            }}
          >
            Accept
          </button>
        </Grid>
      )}
      {isActiveSelectIcon ? <SelectFile setFile={setFile} /> : null}
    </Grid>
  );
};

export default MessageModel;
