import React from "react";
import EmptyIcon from "../Assets/EmptyIcon.png";

const EmptyData = ({ color = "",textColor= "" }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        margin: "20px 0",
        textAlign: "center",
        color: { color },
        fontSize:"20px"
      }}
    >
      <img style={{ marginRight: "5px" }} src={EmptyIcon} alt="x" /> <span style={textColor ? {color: { textColor }} : {color: "black"}}>There is no
      data...</span>
    </div>
  );
};

export default EmptyData;
