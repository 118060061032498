import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

const Timestamp = ({postTimesstamp}) => {

    const [timingPattern, setTimingPattern] = useState("");

    useEffect(()=>{
        const calculateTimingPattern = ()=>{
            const now = new Date();
            const postDate = new Date(postTimesstamp);
            const timeDifference = now - postDate;

            if(timeDifference < 60000){
                const secondAgo = Math.floor(timeDifference / 1000);
                setTimingPattern(`${secondAgo > 0 ? `${secondAgo} seconds ago` : "1 second ago"}`)
            }else if(timeDifference < 3600000){
                const minuteAgo = Math.floor(timeDifference / 60000);
                setTimingPattern(`${minuteAgo > 0 ? `${minuteAgo} minutes ago` : "1 minute ago"}`)
            }else if(timeDifference < 86400000){
                const hoursAgo = Math.floor(timeDifference / 3600000);
                setTimingPattern(`${hoursAgo > 0 ? `${hoursAgo} hours ago` : "1 hour ago"}`)
            }else if(timeDifference < 604800000){
                const monthAgo = Math.floor(timeDifference / 86400000);
                setTimingPattern(`${monthAgo > 0 ? `${monthAgo} months ago` : "1 month ago"}`)
            }else {
                const yearsAgo = Math.floor(timeDifference / 604800000);
                setTimingPattern(`${yearsAgo > 0 ? `${yearsAgo} years ago` : "1 year ago"}`)
            }
        };
        calculateTimingPattern();
    },[postTimesstamp])
  return timingPattern
}

export default Timestamp;
