import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import EmptyData from "../../Components/EmptyData";
import "./info.css";
import Header from "../../Components/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Information = () => {
  const navigate = useNavigate();

  const onclicked = () => {
    navigate("/");
  };

  return (
    <Grid container style={{ marginBottom: "60px" }}>
      <Header />
      <Grid
        style={{
          width: "100%",
          padding: "10px 15px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={onclicked}
          style={{
            padding: "10px 10px",
            textTransform: "none",
            background: "none",
            color: "#ffffff",
            fontSize: "25px",
            fontWeight: "500",
            fontFamily: "'Roboto Condensed', sans-serif",
          }}
        >
          <ArrowBackIcon style={{ color: "#000000", marginRight: 5 }} />
          <b style={{ color: "#000000" }}>Information</b>
        </Button>
      </Grid>
      <Grid
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 10px",
        }}
      >
        <NavLink to="/doanddonts" className="info_card">
          <Typography style={{ paddingLeft: "10px" }}>Do's & Don'ts</Typography>
        </NavLink>
        <NavLink to="/faqs" className="info_card">
          <Typography style={{ paddingLeft: "10px" }}>Faqs</Typography>
        </NavLink>
      </Grid>
    </Grid>
  );
};

export default Information;
