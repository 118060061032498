import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Faqs = () => {
  const navigate = useNavigate();

  const onclicked = () => {
    navigate("/information");
  };

  return (
    <Grid container style={{ marginBottom: "60px" }}>
      <Header />
      <Grid
        style={{
          width: "100%",
          padding: "10px 15px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={onclicked}
          style={{
            padding: "10px 10px",
            textTransform: "none",
            background: "none",
            color: "#ffffff",
            fontSize: "25px",
            fontWeight: "500",
            fontFamily: "'Roboto Condensed', sans-serif",
          }}
        >
          <ArrowBackIcon style={{ color: "#000000", marginRight: 5 }} />
          <b style={{ color: "#000000" }}>Faqs</b>
        </Button>
      </Grid>
      <Grid
        style={{
          maxWidth: "500px",
          margin: "0px auto",
          padding: "0 10px",
        }}
      >
        <Typography
          style={{
            color: "black",
            fontSize: 17,
            fontWeight: "700",
            marginBottom: 15,
            textAlign: "justify",
          }}
        >
          The EIL Technical Conclave 2024 is set to bring together over 500
          industry leaders, experts, and innovators, aiming to address some of
          the most pressing challenges facing India’s industrial landscape. Key
          features of the event include:
        </Typography>
        <Typography
          style={{
            color: "black",
            fontSize: 17,
            fontWeight: "700",
            marginBottom: 15,
            textAlign: "justify",
          }}
        >
          Technical & Strategy Presentations: Gain valuable insights from
          industry-leading technical experts on emerging trends, challenges, and
          innovative solutions shaping the future of the energy sector.
        </Typography>
        <Typography
          style={{
            color: "black",
            fontSize: 17,
            fontWeight: "700",
            marginBottom: 15,
            textAlign: "justify",
          }}
        >
          Technology Showcases: Explore state-of-the-art advancements and
          groundbreaking technologies driving efficiency, sustainability, and
          safety in the hydrocarbons and allied sectors.
        </Typography>
        <Typography
          style={{
            color: "black",
            fontSize: 17,
            fontWeight: "700",
            marginBottom: 15,
            textAlign: "justify",
          }}
        >
          Networking Opportunities: Build meaningful connections with
          professionals, executives, thought leaders, and decision-makers across
          the energy and industrial sectors.
        </Typography>
        <Typography
          style={{
            color: "black",
            fontSize: 17,
            fontWeight: "700",
            marginBottom: 15,
            textAlign: "justify",
          }}
        >
          Case Studies & Knowledge Sharing: Participate in interactive sessions,
          Quizzes and other sessions covering critical topics such as
          sustainability, digital transformation, and risk management.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Faqs;
