import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  Card,
  CardContent,
  Avatar,
  Typography,
  Box,
  TextField,
  CardHeader,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import FAQSLogo from "../../Assets/homelogo.jpeg";
// import leftArrow from "../../Assets/leftBackArrow.png";
import { blue, yellow } from "@mui/material/colors";
import CardImage from "../../Assets/Image__3.png";
// import EDITICON from "../../Assets/EditIcon.png";
// import FAQSLogo from "../../Assets/LeaderboardLogo.png";
import leftArrow from "../../Assets/AgendaIcon.png";
import { OC_ID, SITE_URL } from "../../config";
import Header from "../../Components/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// import CardImage from "../../Assets/Image__3.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Profile = () => {
  let token = localStorage.getItem("token");
  let sessionStorageCurrentUserName = localStorage.getItem("currentUserName");
  let sessionStorageCurrentUserAvatarUrl = JSON.parse(
    localStorage.getItem("currentUserAvatarUrl")
  );
  let sessionStorageCurrentUserId = localStorage.getItem("currentUserId");

  const [editOpen, setEditOpen] = React.useState(false);
  const [imageFile, setImageFile] = useState();
  // const [newUserUpdatedProfile,setNewUserUpdatedProfile] = useState();
  const [UpdatedUserProfileAvatar, setUpdatedUserProfileAvatar] = useState(
    JSON.parse(localStorage.getItem("currentUserAvatarUrl")) || ""
  );
  const [UpdatedUserProfileName, setUpdatedUserProfileName] = useState(
    localStorage.getItem("currentUserName") || ""
  );
  const navigate = useNavigate();
  const [name, setName] = useState(sessionStorageCurrentUserName);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);

  const onclicked = () => {
    navigate("/");
  };

  const handleClose = () => {
    setEditOpen(false);
  };

  const onEdit = () => {
    setEditOpen(true);
  };

  // console.log("UpdatedUserProfile",UpdatedUserProfile)

  useEffect(() => {
    setName(sessionStorageCurrentUserName);
  }, [sessionStorageCurrentUserName]);

  const LogOut = () => {
    localStorage.clear();
    navigate("/");
    // let formData = new FormData();
    // formData.append("logout[uuid]", "");
    // fetch("https://api.sociana.in/api/v1/sessions", {
    //   method: "DELETE",
    //   body: formData,
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
  };

  const handleUpdateImageChange = (e) => {
    console.log(e.target.files[0]);
    setImageFile(e.target.files[0]);
  };

  const handleUpdateNameChange = (e) => {
    setName(e.target.value);
  };

  const onSaveChanges = () => {
    let updateFirstName = "";
    let updateLastName = "";

    const nameParts = name.trim().split(/\s+/);

    if (nameParts.length > 1) {
      updateFirstName = nameParts[0];
      updateLastName = nameParts.slice(1).join(" ");
    } else {
      updateFirstName = nameParts[0];
      updateLastName = "";
    }

    let formdata = new FormData();
    {
      imageFile ? formdata.append("user[avatar]", imageFile) : <></>;
    }
    formdata.append("user[first_name]", updateFirstName);
    formdata.append("user[last_name]", updateLastName);
    try {
      setSaveButtonLoading(true);
      fetch(
        `${SITE_URL}/api/version/v1/app-users/${sessionStorageCurrentUserId}?occasion_id=${OC_ID}`,
        {
          method: "PUT",
          body: formdata,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          return console.log(res), res.json();
        })
        .then((result) => {
          handleClose();
          setSaveButtonLoading(false);
          setImageFile();
          //             currentUserName
          // currentUserAvatarUrl
          if (
            result?.data?.attributes?.avatarUrl &&
            result?.data?.attributes?.name
          ) {
            return (
              localStorage.setItem(
                "currentUserAvatarUrl",
                JSON.stringify(result?.data?.attributes?.avatarUrl)
              ),
              localStorage.setItem(
                "currentUserName",
                result?.data?.attributes?.name
              ),
              setUpdatedUserProfileAvatar(
                JSON.parse(localStorage.getItem("currentUserAvatarUrl"))
              ),
              setUpdatedUserProfileName(localStorage.getItem("currentUserName"))
            );
          }
          if (
            result?.data?.attributes?.avatarUrl &&
            result?.data?.attributes?.name == ""
          ) {
            return (
              localStorage.setItem(
                "currentUserAvatarUrl",
                JSON.stringify(result?.data?.attributes?.avatarUrl)
              ),
              setUpdatedUserProfileAvatar(
                JSON.parse(localStorage.getItem("currentUserAvatarUrl"))
              )
            );
          }
          if (
            !result?.data?.attributes?.avatarUrl &&
            result?.data?.attributes?.name
          )
            return (
              localStorage.setItem(
                "currentUserName",
                result?.data?.attributes?.name
              ),
              setUpdatedUserProfileName(localStorage.getItem("currentUserName"))
            );
        });
    } catch {
      console.log("error");
    }
  };

  // console.log(newUserUpdatedProfile)

  return (
    <>
      <Grid container style={{ marginBottom: "60px" }}>
        <Header />
        <Grid
          style={{
            width: "100%",
            padding: "10px 15px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Button
            onClick={onclicked}
            style={{
              padding: "10px 10px",
              textTransform: "none",
              background: "none",
              color: "#ffffff",
              fontSize: "25px",
              fontWeight: "500",
              fontFamily: "'Roboto Condensed', sans-serif",
            }}
          >
            <ArrowBackIcon style={{ color: "#ffffff", marginRight: 5 }} />

            <b style={{ color: "#ffffff" }}>Profile</b>
          </Button>
        </Grid>
        <Grid
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              width: "400px",
              margin: "15px 0",
              padding: "5px 5px 0 5px",
              borderRadius: "10px",
              textAlign: "center",
            }}
          >
            <Grid
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              {/* UpdatedUserProfileAvatar
UpdatedUserProfileName */}
              <Avatar
                sx={{ bgcolor: "#221C46", width: "120px", height: "120px" }}
                aria-label="recipe"
              >
                {UpdatedUserProfileAvatar ? (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={`${SITE_URL}${UpdatedUserProfileAvatar}`}
                  />
                ) : UpdatedUserProfileName ? (
                  UpdatedUserProfileName.substring(0, 1)
                ) : sessionStorageCurrentUserAvatarUrl !== "null" ? (
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={`${SITE_URL}${sessionStorageCurrentUserAvatarUrl}`}
                  />
                ) : (
                  sessionStorageCurrentUserName.substring(0, 1)
                )}
                {/* // {sessionStorageCurrentUserAvatarUrl !== 'null' ? (
                //   <img
                //     style={{ width: "100%", height: "100%" }}
                //     src={`https://api.sociana.in//${
                //       sessionStorageCurrentUserAvatarUrl
                //     }`}
                //   />
                // ) : (
                //   sessionStorageCurrentUserName.substring(0, 1)
                // )} */}
              </Avatar>
            </Grid>
            <CardContent style={{ padding: "o" }}>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                style={{ padding: "10px 0 0 0" }}
              >
                <b>
                  {UpdatedUserProfileName
                    ? UpdatedUserProfileName
                    : sessionStorageCurrentUserName}
                </b>
              </Typography>
            </CardContent>
            <Button
              onClick={onEdit}
              variant="outlined"
              style={{
                color: "#221C46",
                fontWeight: "600",
                fontSize: "18px",
                // backgroundColor: "transparent",
                // padding: "10px 0",
                textTransform: "none",
                cursor: "pointer",
                boxShadow: "2.5px 2.5px 2px 1.5px #221C46",
                border: "none",
              }}
            >
              Edit Profile
            </Button>
            <Grid style={{ width: "100%", margin: "20px auto 20px auto" }}>
              <Button
                onClick={LogOut}
                style={{
                  width: "80%",
                  color: "#ffffff",
                  fontWeight: "600",
                  fontSize: "18px",
                  background: "rgb(0,96,170)",
                  padding: "10px 0",
                  textTransform: "none",
                  cursor: "pointer",
                  boxShadow: "2.5px 2.5px 2px 1.5px #221C46",
                  border: "none",
                }}
              >
                Sign Out
              </Button>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={editOpen}
        >
          <DialogActions
            style={{
              minWidth: "100%",
              height: "250px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label style={{ fontSize: "20px", fontWeight: "600" }}>
              Upload Profile Picture
            </label>
            <input
              style={{
                width: "100%",
                margin: "20px auto 25px auto",
                cursor: "pointer",
                textAlign: "center",
                paddingLeft: "30px",
              }}
              accept="image/*"
              // className={classes.input}
              id="raised-button-file"
              placeholder="Select Image"
              type="file"
              onChange={handleUpdateImageChange}
            />
            <TextField
              id="outlined-basic"
              variant="outlined"
              onChange={handleUpdateNameChange}
              // value={verificationFormData.name}
              name="name"
              value={name}
              placeholder="Enter your Name"
            />
            {saveButtonLoading ? (
              <Button style={{ marginTop: "25px" }} disabled>
                Loading...
              </Button>
            ) : (
              <Button style={{ marginTop: "25px" }} onClick={onSaveChanges}>
                Save changes
              </Button>
            )}
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </>

    // <Grid container style={{marginBottom:"60px"}}>
    //         <Grid style={{ width: "100%", background: "#ffffff", textAlign: "center", padding: "20px 0" }}>
    //             <img src={FAQSLogo} alt="Logo" />
    //         </Grid>
    //         <Grid style={{ width: "100%", padding: "20px 20px", display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
    //             <Button onClick={onclicked} style={{ padding: "10px 10px", textTransform: "none", background: "none", color: "#ffffff", fontSize: "25px", fontWeight: "500", fontFamily: "'Roboto Condensed', sans-serif" }}><img style={{ marginRight: "10px" }} src={leftArrow} alt='<-' /><b>Profile</b></Button>
    //         </Grid>
    //         <Grid style={{ width: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: "center" }}>
    //                 <Card sx={{ width: "400px", margin: "15px 0", padding: "5px 5px 0 5px", borderRadius: "10px" }}>
    //                     {/* <Avatar
    //                     sx={{  width: "50px", height: "50px" }}
    //                     aria-label="recipe"
    //                   >
    //                     {sessionStorageCurrentUserAvatarUrl ? (
    //                       <img
    //                         style={{ width: "100%", height: "100%" }}
    //                         src={https://api.sociana.in//${sessionStorageCurrentUserAvatarUrl}}
    //                         alt="Logo"
    //                       />
    //                     ) : (
    //                       sessionStorageCurrentUserName.substring(0, 1)
    //                     )}
    //                   </Avatar>
    //                     <CardContent>
    //                         <Typography gutterBottom variant="h5" component="div">
    //                            <b>Name: {sessionStorageCurrentUserName}</b>
    //                         </Typography>
    //                         <Typography variant="body2" color="text.secondary">
    //                             Here is the description
    //                         </Typography>
    //                     </CardContent> */}
    //                     <CardHeader
    //       avatar={
    //         <Avatar
    //                     sx={{  width: "50px", height: "50px" }}
    //                     aria-label="recipe"
    //                   >
    //                     {sessionStorageCurrentUserAvatarUrl ? (
    //                       <img
    //                         style={{ width: "100%", height: "100%" }}
    //                         src={https://api.sociana.in//${sessionStorageCurrentUserAvatarUrl}}
    //                         alt="Logo"
    //                       />
    //                     ) : (
    //                       sessionStorageCurrentUserName.substring(0, 1)
    //                     )}
    //                   </Avatar>
    //       }

    //     />
    //     <CardContent>
    //                         <Typography gutterBottom variant="h5" component="div">
    //                            <b>Name: {sessionStorageCurrentUserName}</b>
    //                         </Typography>
    //                         <Typography variant="body2" color="text.secondary">
    //                             Here is the description
    //                         </Typography>
    //                     </CardContent>
    //                 </Card>

    //             </Grid>
    // </Grid>
  );
};

export default Profile;
