import React from "react";
import { Grid, Button, CircularProgress } from "@mui/material";

const Btn = ({ btnName, onclicked, loading }) => {
  return (
    <Grid style={{ width: "100%", margin: "15px 0 10px 0" }}>
      <Button
        onClick={onclicked}
        style={{
          width: "100%",
          color: "#ffffff",
          fontWeight: "600",
          fontSize: "18px",
          background: "rgb(0,96,170)",
          padding: "10px 0",
          textTransform: "none",
        }}
      >
        {!!loading ? (
          <CircularProgress style={{ color: "#ffffff" }} />
        ) : (
          btnName
        )}
      </Button>
    </Grid>
  );
};

export default Btn;
