import { Grid, Typography } from "@mui/material";
import React from "react";
import DefaultUserImage from "../../../Assets/default_profile_pic.png";
import { OC_ID, SITE_URL } from "../../../config";

// const SITE_URL = "https://api.sociana.in/";

const ChatUserCard = ({ usersData, onPress }) => {
  return (
    <button
      style={{
        width: "100%",
        paddingTop: "5px",
        paddingBottom: "5px",
        border: "none",
        borderBottomWidth: 1,
        borderBottomColor: "#000000",
        background: "#E3E3E3",
        borderRadius: "5px",
        marginBottom: 10,
      }}
      onClick={() => onPress(usersData)}
    >
      <Grid
        style={{
          width: "95%",
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        {usersData.attributes?.avatarUrl ? (
          <Grid
            style={{
              width: "40px",
              height: "40px",
              borderRadius: 20,
              overflow: "hidden",
              marginRight: 10,
              border: "1px solid rgb(0,96,170)",
              // marginTop: 20,
            }}
          >
            <img
              src={`${SITE_URL}${usersData.attributes?.avatarUrl}`}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              alt="Null"
            />
          </Grid>
        ) : (
          <Grid
            style={{
              width: "40px",
              height: "40px",
              borderRadius: 20,
              overflow: "hidden",
              marginRight: 10,
              border: "1px solid rgb(0,96,170)",
              // marginTop: 20,
            }}
          >
            <img
              src={DefaultUserImage}
              alt="Null"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </Grid>
        )}
        <Grid
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyItems: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              maxWidth: 240,
              color: "#000000",
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
          >
            {usersData.attributes?.name}
          </Typography>
          {!!usersData?.attributes?.email ? (
            <Typography
              variant="body2"
              sx={{
                maxWidth: 240,
                marginBottom: "2px",
                color: "#000000",
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                color: "rgb(0,96,170)",
              }}
            >
              {usersData.attributes?.email}
            </Typography>
          ) : null}
          {/* {!!usersData?.attributes?.city ? (
            <Typography
              variant="body2"
              sx={{
                maxWidth: 240,
                marginBottom: "2px",
                color: "#000000",
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
              }}
            >
              {usersData.attributes?.city}
            </Typography>
          ) : null} */}
        </Grid>
      </Grid>
    </button>
  );
};

export default ChatUserCard;
