import { Grid, Typography } from "@mui/material";
import React from "react";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { OC_ID, SITE_URL } from "../../../config";

// const SITE_URL = "https://api.sociana.in/";
const getFileType = (url) => {
  const extension = url?.split(".").pop().toLowerCase();
  const imageExtensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "svg",
    "tiff",
    "webp",
  ];
  const videoExtensions = [
    "mp4",
    "avi",
    "mov",
    "mkv",
    "wmv",
    "flv",
    "m4v",
    "3gp",
  ];

  if (imageExtensions.includes(extension)) {
    return "image";
  } else if (videoExtensions.includes(extension)) {
    return "video";
  } else {
    return "file";
  }
};

const getUserFriendlyTimeDuration = (dateString) => {
  const diffTime = new Date().getTime() - new Date(dateString).getTime();

  if (diffTime < 60 * 1000) {
    return "just now";
  }
  if (diffTime >= 60 * 1000 && diffTime < 60 * 60 * 1000) {
    return Math.floor(diffTime / (1000 * 60)) === 1
      ? "1 min ago"
      : `${Math.floor(diffTime / (1000 * 60))} minutes ago`;
  }
  if (diffTime >= 60 * 60 * 1000 && diffTime < 24 * 60 * 60 * 1000) {
    return Math.floor(diffTime / (1000 * 60 * 60)) === 1
      ? "1 hour ago"
      : `${Math.floor(diffTime / (1000 * 60 * 60))} hours ago`;
  }
  if (diffTime >= 24 * 60 * 60 * 1000 && diffTime < 30 * 24 * 60 * 60 * 1000) {
    return Math.floor(diffTime / (24 * 60 * 60 * 1000)) === 1
      ? "1 day ago"
      : `${Math.floor(diffTime / (24 * 60 * 60 * 1000))} days ago`;
  }
  if (
    diffTime >= 30 * 24 * 60 * 60 * 1000 &&
    diffTime < 12 * 30 * 24 * 60 * 60 * 1000
  ) {
    return Math.floor(diffTime / (30 * 24 * 60 * 60 * 1000)) === 1
      ? "1 month ago"
      : `${Math.floor(diffTime / (30 * 24 * 60 * 60 * 1000))} months ago`;
  }
  if (diffTime >= 12 * 30 * 24 * 60 * 60 * 1000) {
    return Math.floor(diffTime / (12 * 30 * 24 * 60 * 60 * 1000)) === 1
      ? "1 year ago"
      : `${Math.floor(diffTime / (12 * 30 * 24 * 60 * 60 * 1000))} years ago`;
  }
};

const ChatMessage = ({
  profileImage,
  postedBy,
  occasionId,
  currentUserId,
  createdBy,
  message,
  id,
  postedOn,
  messageMediaUrl,
  messageMediaThumbUrl,
  isRead,
}) => {
  const isCurrentUser = createdBy?.id == currentUserId;
  console.log("messageMediaThumbUrl", messageMediaThumbUrl);
  return (
    <Grid>
      <Grid>
        <Grid
          style={{
            marginBottom: "12px",
            marginTop: "6px",
            marginLeft: "10px",
            marginRight: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: isCurrentUser ? "flex-end" : "flex-start",
          }}
        >
          {!!message && !messageMediaUrl && (
            <Grid
              style={{
                maxWidth: "80%",
                display: "flex",
                flexDirection: "column",
                background: isCurrentUser ? "#5C2C5C" : "#E3E3E3",
                border: "1px solid #5C2C5C",
                borderRadius: 5,
                padding: " 5px 10px",
              }}
            >
              <Typography
                variant="body1"
                style={{
                  width: "100%",
                  textAlign: "left",
                  lineHeight: "18px",
                  color: isCurrentUser ? "#ffffff" : "#000000",
                  wordBreak: "break-all", // This ensures long words (like emails) will wrap
                }}
              >
                {message}
              </Typography>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontSize: "10px",
                    marginRight: 5,
                    color: isCurrentUser ? "#ffffff" : "#000000",
                  }}
                >
                  {getUserFriendlyTimeDuration(postedOn)}
                </Typography>
                {isCurrentUser ? (
                  isRead ? (
                    <DoneAllIcon style={{ color: "blue", fontSize: 12 }} />
                  ) : (
                    <DoneIcon
                      style={{
                        color: isCurrentUser ? "#ffffff" : "#000000",
                        fontSize: 12,
                      }}
                    />
                  )
                ) : null}
              </Grid>
            </Grid>
          )}
          {!!messageMediaUrl && !message ? (
            getFileType(messageMediaUrl) === "image" ? (
              <Grid
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  background: isCurrentUser ? "#5C2C5C" : "#E3E3E3",
                  border: "1px solid #5C2C5C",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={`${SITE_URL}${messageMediaUrl}`}
                  style={{ width: "100%", height: 200, objectFit: "contain" }}
                />
                <Grid
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: 5,
                  }}
                >
                  <Typography
                    style={{
                      marginRight: 5,
                      fontSize: "10px",
                      color: isCurrentUser ? "#ffffff" : "#000000",
                    }}
                  >
                    {getUserFriendlyTimeDuration(postedOn)}
                  </Typography>
                  {isCurrentUser ? (
                    isRead ? (
                      <DoneAllIcon style={{ color: "blue", fontSize: 12 }} />
                    ) : (
                      <DoneIcon
                        style={{
                          color: isCurrentUser ? "#ffffff" : "#000000",
                          fontSize: 12,
                        }}
                      />
                    )
                  ) : null}
                </Grid>
              </Grid>
            ) : getFileType(messageMediaUrl) === "video" ? (
              <Grid
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  background: isCurrentUser ? "#5C2C5C" : "#E3E3E3",
                  border: "1px solid #5C2C5C",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <video
                  src={`${SITE_URL}${messageMediaUrl}`}
                  poster={`${SITE_URL}${messageMediaThumbUrl}`}
                  controls
                  style={{ width: "100%", height: 200 }}
                />
                <Grid
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: 5,
                  }}
                >
                  <Typography
                    style={{
                      marginRight: 5,
                      fontSize: "10px",
                      color: isCurrentUser ? "#ffffff" : "#000000",
                    }}
                  >
                    {getUserFriendlyTimeDuration(postedOn)}
                  </Typography>
                  {isCurrentUser ? (
                    isRead ? (
                      <DoneAllIcon style={{ color: "blue", fontSize: 12 }} />
                    ) : (
                      <DoneIcon
                        style={{
                          color: isCurrentUser ? "#ffffff" : "#000000",
                          fontSize: 12,
                        }}
                      />
                    )
                  ) : null}
                </Grid>
              </Grid>
            ) : (
              <Grid
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  background: isCurrentUser ? "#5C2C5C" : "#E3E3E3",
                  border: "1px solid #5C2C5C",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <InsertDriveFileIcon
                  style={{
                    fontSize: "60px",
                    color: isCurrentUser ? "#ffffff" : "#000000",
                  }}
                />
                <Grid
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: 5,
                  }}
                >
                  <Typography
                    style={{
                      marginRight: 5,
                      fontSize: "10px",
                      color: isCurrentUser ? "#ffffff" : "#000000",
                    }}
                  >
                    {getUserFriendlyTimeDuration(postedOn)}
                  </Typography>
                  {isCurrentUser ? (
                    isRead ? (
                      <DoneAllIcon style={{ color: "blue", fontSize: 12 }} />
                    ) : (
                      <DoneIcon
                        style={{
                          color: isCurrentUser ? "#ffffff" : "#000000",
                          fontSize: 12,
                        }}
                      />
                    )
                  ) : null}
                </Grid>
              </Grid>
            )
          ) : null}
          {!!messageMediaUrl && !!message ? (
            getFileType(messageMediaUrl) === "image" ? (
              <Grid
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  background: isCurrentUser ? "#5C2C5C" : "#E3E3E3",
                  border: "1px solid #5C2C5C",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={`${SITE_URL}${messageMediaUrl}`}
                  style={{ width: "100%", height: 200, objectFit: "contain" }}
                />
                <Typography
                  variant="body1"
                  style={{
                    width: "100%",
                    textAlign: "left",
                    lineHeight: "18px",
                    marginTop: 5,
                    color: isCurrentUser ? "#ffffff" : "#000000",
                    wordBreak: "break-all",
                  }}
                >
                  {message}
                </Typography>
                <Grid
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: 5,
                  }}
                >
                  <Typography
                    style={{
                      marginRight: 5,
                      fontSize: "10px",
                      color: isCurrentUser ? "#ffffff" : "#000000",
                    }}
                  >
                    {getUserFriendlyTimeDuration(postedOn)}
                  </Typography>
                  {isCurrentUser ? (
                    isRead ? (
                      <DoneAllIcon style={{ color: "blue", fontSize: 12 }} />
                    ) : (
                      <DoneIcon
                        style={{
                          color: isCurrentUser ? "#ffffff" : "#000000",
                          fontSize: 12,
                        }}
                      />
                    )
                  ) : null}
                </Grid>
              </Grid>
            ) : getFileType(messageMediaUrl) === "video" ? (
              <Grid
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  background: isCurrentUser ? "#5C2C5C" : "#E3E3E3",
                  border: "1px solid #5C2C5C",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <video
                  src={`${SITE_URL}${messageMediaUrl}`}
                  poster={`${SITE_URL}${messageMediaThumbUrl}`}
                  controls
                  style={{ width: "100%", height: 200 }}
                />
                <Typography
                  variant="body1"
                  style={{
                    width: "100%",
                    textAlign: "left",
                    lineHeight: "18px",
                    marginTop: 5,
                    color: isCurrentUser ? "#ffffff" : "#000000",
                    wordBreak: "break-all",
                  }}
                >
                  {message}
                </Typography>
                <Grid
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: 5,
                  }}
                >
                  <Typography
                    style={{
                      marginRight: 5,
                      fontSize: "10px",
                      color: isCurrentUser ? "#ffffff" : "#000000",
                    }}
                  >
                    {getUserFriendlyTimeDuration(postedOn)}
                  </Typography>
                  {isCurrentUser ? (
                    isRead ? (
                      <DoneAllIcon style={{ color: "blue", fontSize: 12 }} />
                    ) : (
                      <DoneIcon
                        style={{
                          color: isCurrentUser ? "#ffffff" : "#000000",
                          fontSize: 12,
                        }}
                      />
                    )
                  ) : null}
                </Grid>
              </Grid>
            ) : (
              <Grid
                style={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  background: isCurrentUser ? "#5C2C5C" : "#E3E3E3",
                  border: "1px solid #5C2C5C",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <InsertDriveFileIcon
                  style={{
                    fontSize: "60px",
                    color: isCurrentUser ? "#ffffff" : "#000000",
                  }}
                />
                <Typography
                  variant="body1"
                  style={{
                    width: "100%",
                    textAlign: "left",
                    lineHeight: "18px",
                    marginTop: 5,
                    color: isCurrentUser ? "#ffffff" : "#000000",
                    wordBreak: "break-all",
                  }}
                >
                  {message}
                </Typography>
                <Grid
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: 5,
                  }}
                >
                  <Typography
                    style={{
                      marginRight: 5,
                      fontSize: "10px",
                      color: isCurrentUser ? "#ffffff" : "#000000",
                    }}
                  >
                    {getUserFriendlyTimeDuration(postedOn)}
                  </Typography>
                  {isCurrentUser ? (
                    isRead ? (
                      <DoneAllIcon style={{ color: "blue", fontSize: 12 }} />
                    ) : (
                      <DoneIcon
                        style={{
                          color: isCurrentUser ? "#ffffff" : "#000000",
                          fontSize: 12,
                        }}
                      />
                    )
                  ) : null}
                </Grid>
              </Grid>
            )
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChatMessage;
