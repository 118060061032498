import { createConsumer } from "@rails/actioncable";

const createCable = async () => {
  let token = localStorage.getItem("token");
  const SERVER_URL = "actioncable-f9e665905641.herokuapp.com/";
  const occasion_id = 100;
  const CableApp = {};
  CableApp.cable = createConsumer(
    `wss://${SERVER_URL}cable?token=${token}&occasion_id=${occasion_id}`
  );
  return CableApp;
};

export default createCable;
