import React, { useEffect, useState } from "react";
import { OC_ID, SITE_URL } from "../config";
import { Grid, Typography } from "@mui/material";

const PinnedNotifications = () => {
  let token = localStorage.getItem("token");
  const [pinnedNotificationData, setPinnedNotificationData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const fetchPinnedNotification = async () => {
    const response = await fetch(
      `${SITE_URL}/api/version/v1/oc/${OC_ID}/sticky-scheduled-notifications?modelable_id=${OC_ID}&modelable_type=Occasion`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();

    setPinnedNotificationData(data?.data);
  };

  useEffect(() => {
    fetchPinnedNotification();
  }, []);

  useEffect(() => {
    if (pinnedNotificationData && pinnedNotificationData.length > 0) {
      const intervalId = setInterval(() => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % pinnedNotificationData.length
        );
      }, 3000);

      // Clean up interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [pinnedNotificationData, currentIndex]);

  return pinnedNotificationData && pinnedNotificationData.length > 0 ? (
    <Grid
      style={{
        width: "100%",
        minHeight: 60,
        backgroundColor: "rgb(80,112,143)",
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 10,
        paddingRight: 10,
        borderLeft: "5px solid rgb(0,96,170)",
        marginBottom: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      {pinnedNotificationData &&
        pinnedNotificationData.length > 0 &&
        pinnedNotificationData[currentIndex] && (
          <Typography
            style={{
              color: "#ffffff",
              fontWeight: "700",
              fontSize: 14,
            }}
          >
            {pinnedNotificationData[currentIndex]?.attributes?.message}
          </Typography>
        )}
    </Grid>
  ) : null;
};

export default PinnedNotifications;
