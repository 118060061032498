import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Button, Typography, Box } from "@mui/material";
import QALogo from "../../Assets/homelogo.jpeg";
import leftIcon from "../../Assets/AgendaIcon.png";
import leftArrow from "../../Assets/leftBackArrow.png";
import RightArrow from "../../Assets/RightIcon.png";
import Quizone from "./Quizone";
import CircularProgress from "@mui/material/CircularProgress";
import { OC_ID, SITE_URL } from "../../config";
import Header from "../../Components/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Triviaquiz = () => {
  let token = localStorage.getItem("token");
  const [questionData, setQuestionData] = useState({
    data: [],
    included: [],
    links: {},
    meta: {},
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [page, setPage] = React.useState(1);
  const [totalCorrect, setTotalCorrect] = useState(0);
  const [totalInCorrect, setTotalInCorrect] = useState(0);
  const [optionSelectedClear, setOptionSelectedClear] = useState();

  const navigate = useNavigate();

  const onclicked = () => {
    navigate("/");
  };

  useEffect(() => {
    try {
      fetch(
        `${SITE_URL}/api/version/v2/oc/${OC_ID}/visitant/poll_post/fetch-random-posts-by-poll-question-type?page=${page}&question_type=quiz`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((results) => {
          if (results) {
            setQuestionData({
              data: questionData?.data.concat(results?.data),
              included: questionData?.included.concat(results?.included),
              links: results?.links,
              meta: results?.meta,
            });
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page]);

  const currentData = React.useMemo(() => {
    return questionData.data[currentIndex];
  }, [currentIndex, questionData.data]);

  const handlePrev = () => {
    setCurrentIndex((index) => index - 1);
  };

  const handleNext = () => {
    setCurrentIndex((index) => index + 1);
    setOptionSelectedClear();
  };

  const getNextPage = (str) => {
    const str1 = str.split("=");
    const res = str1[1].split("&");
    return parseInt(res[0], 10);
  };

  const onLoadMore = React.useMemo(() => {
    if (!!questionData?.links?.next) {
      const pageNumber = getNextPage(questionData?.links?.next);
      setPage(pageNumber);
    }
  }, [questionData?.links?.next]);

  console.log("questionData", questionData);

  return (
    <>
      <Grid container style={{ marginBottom: "60px" }}>
        <Header />
        <Grid
          style={{
            width: "100%",
            padding: "10px 15px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Button
            onClick={onclicked}
            style={{
              padding: "10px 10px 10px 5px",
              textTransform: "none",
              background: "none",
              color: "#ffffff",
              fontSize: "25px",
              fontWeight: "500",
              fontFamily: "'Roboto Condensed', sans-serif",
            }}
          >
            <ArrowBackIcon style={{ color: "#ffffff", marginRight: 10 }} />
            <b style={{ color: "#ffffff" }}>Trivia Quiz</b>
          </Button>
        </Grid>
        {currentData ? (
          <Grid
            style={{ width: "100%", padding: "0px 10px", marginBottom: "30px" }}
          >
            <Grid
              style={{
                minwidth: "300px",
                maxWidth: "500px",
                margin: "auto",
                background: "#ffffff",
                padding: "15px",
                borderRadius: "10px",
              }}
            >
              <Grid
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                {currentIndex == 0 ? (
                  <></>
                ) : (
                  <Button onClick={handlePrev} style={{ margin: "0 10px" }}>
                    <ArrowBackIcon style={{ color: "#000000" }} />
                  </Button>
                )}
                <Typography
                  style={{
                    margin: "0 10px",
                    color: "black",
                    fontFamily: '"Roboto Condensed", sans-serif',
                  }}
                  variant="h5"
                >
                  {currentIndex + 1}/
                  {questionData?.meta?.answerDetails?.totalAnsweredQuestions +
                    questionData?.meta?.answerDetails?.totalUnansweredQuestions}
                </Typography>
                {currentIndex + 1 == questionData.data.length ? (
                  onLoadMore
                ) : (
                  <Button onClick={handleNext} style={{ margin: "0 10px" }}>
                    <ArrowForwardIcon style={{ color: "#000000" }} />
                  </Button>
                )}
              </Grid>
              <Grid>
                <Typography
                  style={{
                    width: "100%",
                    fontSize: "20px",
                    color: "#000000",
                    fontWeight: "500",
                    margin: "10px 0",
                    fontFamily: '"Roboto Condensed", sans-serif',
                  }}
                >
                  {currentData?.attributes?.question}
                </Typography>
              </Grid>
              <Grid className="options">
                <Quizone
                  currentData={currentData}
                  included={questionData?.included}
                  correct={questionData?.included
                    .filter((el) =>
                      currentData?.relationships?.pollPostOptions?.data?.find(
                        (ele) => ele.id === el.id
                      )
                    )
                    .filter((element) => {
                      return element?.attributes?.correctOptionFlag === true;
                    })}
                  totalCorrect={totalCorrect}
                  setTotalCorrect={setTotalCorrect}
                  totalInCorrect={totalInCorrect}
                  setTotalInCorrect={setTotalInCorrect}
                  setOptionSelectedClear={setOptionSelectedClear}
                  optionSelectedClear={optionSelectedClear}
                  token={token}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress style={{ color: "white" }} />
          </Box>
        )}
      </Grid>
    </>
  );
};

export default Triviaquiz;
