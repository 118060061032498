import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DetailsModal = ({ isModalOpen, handleClose, title, desOne, desTwo }) => {
  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {desOne}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {desTwo}
        </Typography>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: "green",
              color: "#fff",
              padding: "10px 15px",

              "&:hover": {
                backgroundColor: "green",
              },
            }}
          >
            Ok
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DetailsModal;
