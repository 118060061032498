import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const DoDont = () => {
  const navigate = useNavigate();

  const onclicked = () => {
    navigate("/information");
  };

  return (
    <Grid container style={{ marginBottom: "60px" }}>
      <Header />
      <Grid
        style={{
          width: "100%",
          padding: "10px 15px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={onclicked}
          style={{
            padding: "10px 10px",
            textTransform: "none",
            background: "none",
            color: "#ffffff",
            fontSize: "25px",
            fontWeight: "500",
            fontFamily: "'Roboto Condensed', sans-serif",
          }}
        >
          <ArrowBackIcon style={{ color: "#000000", marginRight: 5 }} />
          <b style={{ color: "#000000" }}>Do's & Don'ts</b>
        </Button>
      </Grid>
      <Grid
        style={{
          maxWidth: "500px",
          margin: "0px auto",
          padding: "0 10px",
        }}
      >
        <Typography
          style={{
            color: "rgb(0,96,170)",
            fontSize: 18,
            fontWeight: "800",
            marginBottom: 10,
            textAlign: "center",
          }}
        >
          Do's
        </Typography>
        <Typography
          style={{
            color: "black",
            fontSize: 17,
            fontWeight: "700",
            marginBottom: 15,
            textAlign: "justify",
          }}
        >
          1. Arrive Early: Be punctual to maximize networking opportunities and
          ensure you don't miss any sessions.
        </Typography>
        <Typography
          style={{
            color: "black",
            fontSize: 17,
            fontWeight: "700",
            marginBottom: 15,
            textAlign: "justify",
          }}
        >
          2. Dress Professionally: Business formal or smart casual attire is
          recommended.
        </Typography>
        <Typography
          style={{
            color: "black",
            fontSize: 17,
            fontWeight: "700",
            marginBottom: 15,
            textAlign: "justify",
          }}
        >
          3. Bring Business Cards: Use this opportunity to exchange contact
          information with other professionals.
        </Typography>
        <Typography
          style={{
            color: "black",
            fontSize: 17,
            fontWeight: "700",
            marginBottom: 15,
            textAlign: "justify",
          }}
        >
          4. Engage Actively: Participate in discussions, ask questions, and
          share your insights during Q&A sessions.
        </Typography>
        <Typography
          style={{
            color: "black",
            fontSize: 17,
            fontWeight: "700",
            marginBottom: 15,
            textAlign: "justify",
          }}
        >
          5. Take Notes: Document key points and ideas shared by speakers for
          future reference.
        </Typography>

        <Typography
          style={{
            color: "rgb(0,96,170)",
            fontSize: 18,
            fontWeight: "800",
            marginBottom: 10,
            textAlign: "center",
          }}
        >
          Don'ts
        </Typography>
        <Typography
          style={{
            color: "black",
            fontSize: 17,
            fontWeight: "700",
            marginBottom: 15,
            textAlign: "justify",
          }}
        >
          1. Don't Overlook Networking: Use breaks to connect with peers,
          exhibitors, and speakers.
        </Typography>
        <Typography
          style={{
            color: "black",
            fontSize: 17,
            fontWeight: "700",
            marginBottom: 15,
            textAlign: "justify",
          }}
        >
          2. Don't Ignore Event Guidelines: Follow the instructions provided by
          the organizers to ensure a smooth experience.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DoDont;
