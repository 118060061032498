import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../../Components/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { OC_ID, SITE_URL } from "../../config";

const Survey = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const onclicked = () => {
    navigate("/");
  };

  const [hospitilityData, setHospitilityData] = useState([]);
  const [isHospitilityLoading, setIsHospitilityLoading] = useState(false);
  const [inputValues, setInputValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarData, setSnackbarData] = React.useState({
    message: "",
    type: "success",
  });

  useEffect(() => {
    setIsHospitilityLoading(true);
    fetch(
      `${SITE_URL}/api/version/v2/oc/${OC_ID}/visitant/hospitality/fetch-task-with-answer-in-hospitality-by-category?category_name[]=Survey_Details&responser=true`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result?.data) {
          setIsHospitilityLoading(false);
          const { data } = result;
          setHospitilityData([...data]);
        } else {
          setIsHospitilityLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    if (hospitilityData && hospitilityData.length > 0) {
      const initialValues = hospitilityData.map((item) => ({
        id: item.id,
        value:
          item.current_user_requested_hospitality_task_answers.length > 0
            ? item.current_user_requested_hospitality_task_answers[0].answer
            : item.data_input_type === "mcq" || item.data_input_type === "scq"
            ? []
            : "",
        isReadOnly:
          item.current_user_provided_hospitality_task_answers.length > 0,
      }));
      setInputValues(initialValues);
    }
  }, [hospitilityData]);

  const handleCloseSnackbar = () => {
    setSnackbarData({
      message: "",
      type: "success",
    });
  };

  const handleInputChange = (id, value) => {
    setInputValues((prev) => {
      const updatedInputs = [...prev];
      const inputIndex = updatedInputs.findIndex((input) => input.id === id);

      if (inputIndex > -1) {
        updatedInputs[inputIndex].value = value;
      } else {
        updatedInputs.push({ id, value, isReadOnly: false });
      }

      return updatedInputs;
    });
  };
  const handleOptionSelect = (id, option, isMultiple) => {
    setInputValues((prev) => {
      const updatedInputs = [...prev];
      const inputIndex = updatedInputs.findIndex((input) => input.id === id);

      if (inputIndex > -1) {
        if (isMultiple) {
          const currentValue = Array.isArray(updatedInputs[inputIndex].value)
            ? updatedInputs[inputIndex].value
            : [];

          const isSelected = currentValue.includes(option);
          const updatedValue = isSelected
            ? currentValue.filter((opt) => opt !== option)
            : [...currentValue, option];

          updatedInputs[inputIndex].value = updatedValue;
        } else {
          updatedInputs[inputIndex].value = [option];
        }
      } else {
        updatedInputs.push({
          id,
          value: isMultiple ? [option] : [option],
          isReadOnly: false,
        });
      }
      return updatedInputs;
    });
  };

  const handleSubmit = () => {
    if (inputValues && inputValues.length > 0) {
      setLoading(true);
      const formData = new FormData();

      inputValues.forEach((input) => {
        if (
          input?.value &&
          typeof input?.value === "string" &&
          input?.value !== ""
        ) {
          formData.append(
            "hospitality_task_answer[][hospitality_task_id]",
            input.id
          );
          formData.append("hospitality_task_answer[][answer]", input.value);
        } else if (
          input?.value &&
          Array.isArray(input?.value) &&
          input?.value.length > 0
        ) {
          const joinedValue = input.value.join(",");
          formData.append(
            "hospitality_task_answer[][hospitality_task_id]",
            input.id
          );
          formData.append("hospitality_task_answer[][answer]", joinedValue);
        }
      });

      fetch(
        `${SITE_URL}/api/version/v2/oc/${OC_ID}/visitant/hospitality/bulk-modify-hospitality-requested-tasks-answers?responser=true`,
        {
          method: "PUT",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          if (!res.ok) {
            setSnackbarData({
              message: "Something Went Wrong.",
              type: "error",
            });
          }
          return res.json();
        })
        .then((result) => {
          if (result?.data) {
            const { data } = result;
            const existingHospitilityIndex = hospitilityData.findIndex(
              (item) => item.id === data.id
            );

            if (existingHospitilityIndex !== -1) {
              const updatedHospitilityData = hospitilityData.map(
                (item, index) =>
                  index === existingHospitilityIndex ? data : item
              );

              setHospitilityData(updatedHospitilityData);
            }
            setSnackbarData({
              message: "Task Answers Updated Successfully",
              type: "success",
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false); // Handle errors and reset loading state
          setSnackbarData({
            message: "Something Went Wrong.",
            type: "error",
          });
        });
    } else {
      setLoading(false); // Reset loading if no inputValues are provided
    }
  };

  return (
    <>
      <Grid container style={{ marginBottom: "60px" }}>
        <Header />
        <Grid
          style={{
            width: "100%",
            padding: "10px 15px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Button
            onClick={onclicked}
            style={{
              padding: "10px 10px 10px 5px",
              textTransform: "none",
              background: "none",
              color: "#ffffff",
              fontSize: "25px",
              fontWeight: "500",
              fontFamily: "'Roboto Condensed', sans-serif",
            }}
          >
            <ArrowBackIcon style={{ color: "#ffffff", marginRight: 10 }} />
            <b style={{ color: "#ffffff" }}>Survey</b>
          </Button>
        </Grid>
        <Grid
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 10px",
          }}
        >
          {/* <Grid style={{ padding: 20, maxWidth: "500px", margin: "auto" }}>
            {hospitilityData && hospitilityData.length > 0 ? (
              <>
                {hospitilityData.map((item, index) => {
                  const inputValue = inputValues.find(
                    (input) => input.id === item.id
                  );
                  return (
                    <Grid
                      key={item.id}
                      style={{
                        margin: "10px 0",
                        borderRadius: 5,
                        border: "1px solid white",
                        padding: "14px 8px ",
                        backgroundColor: "rgba(0, 160, 154, 0.8)",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "700",
                          fontSize: 15,
                          color: "#ffffff",
                          marginBottom: 10,
                          textAlign: "justify",
                        }}
                      >
                        {index + 1}: {item.name}
                      </Typography>
                      {item.data_input_type === "text" ? (
                        <TextField
                          placeholder="Write your answer..."
                          value={inputValue?.value || ""}
                          onChange={(e) =>
                            handleInputChange(item.id, e.target.value)
                          }
                          sx={{
                            backgroundColor: "#fff",
                            width: "100%",
                            borderRadius: "5px",
                            "& fieldset": { border: "none" },
                          }}
                          editable={!inputValue?.isReadOnly}
                        />
                      ) : (
                        <Grid
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexWrap: "wrap",
                            gap: 10,
                          }}
                        >
                          {item?.hospitality_task_options?.map(
                            (option, index) => (
                              <Button
                                key={index}
                                style={{
                                  padding: 10,
                                  borderRadius: 5,
                                  backgroundColor: inputValue?.value.includes(
                                    option.option
                                  )
                                    ? "rgba(77, 169, 80, 1)" // Active color
                                    : "#ffffff",
                                }}
                                onClick={() =>
                                  handleOptionSelect(
                                    item.id,
                                    option.option,
                                    item.data_input_type === "mcq"
                                  )
                                }
                                disabled={inputValue?.isReadOnly}
                              >
                                <Typography
                                  style={{
                                    color: inputValue?.value.includes(
                                      option.option
                                    )
                                      ? "#ffffff"
                                      : "#000000",
                                  }}
                                >
                                  {option.option}
                                </Typography>
                              </Button>
                            )
                          )}
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
                <Grid
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!!loading ? (
                    <Button
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: 5,
                        padding: "10px 20px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: 15,
                      }}
                    >
                      <CircularProgress size="1.5rem" />
                    </Button>
                  ) : (
                    <Button
                      onClick={handleSubmit}
                      style={{
                        backgroundColor: "#ffffff",
                        borderRadius: 5,
                        padding: "10px 20px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginTop: 15,
                        color: "green",
                        fontWeight: "800",
                      }}
                    >
                      Submit
                    </Button>
                  )}
                </Grid>
              </>
            ) : (
              <Grid
                style={{
                  width: "100%",
                  marginTop: 100,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    color: "#ffffff",
                    textAlign: "center",
                    fontWeight: "700",
                  }}
                >
                  {isHospitilityLoading ? "Loading..." : "No data is there..."}
                </Typography>
              </Grid>
            )}
          </Grid>  */}
          <Typography
            variant="h6"
            style={{ color: "#ffffff", textAlign: "center" }}
          >
            "We'd love to hear your thoughts—click here to share your feedback!"
          </Typography>
          <Typography variant="h6" style={{ color: "#ffffff" }}>
            (Link will be updated shortly)
          </Typography>
        </Grid>
      </Grid>
      {/* <Snackbar
        open={!!snackbarData.message}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleCloseSnackbar}
        style={{ borderRadius: 5 }}
        message={snackbarData.message}
      >
        <Alert severity={snackbarData.type}>{snackbarData.message}</Alert>
      </Snackbar> */}
    </>
  );
};

export default Survey;
