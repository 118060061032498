import { useEffect, useState } from "react";
import HomeVideo from "../Assets/videos/HomeVideo.mp4";
import "../ComponentStyles/video.css";
import { OC_ID, SITE_URL } from "../config";
import { Grid, Typography } from "@mui/material";

export const Video = () => {
  let token = localStorage.getItem("token");
  const [videoData, setVideoData] = useState([]);
  const fetchVideo = async () => {
    const response = await fetch(
      `${SITE_URL}/api/version/v1/oc/${OC_ID}/fetch-occasion-assets-by-category?resource_category=welcome_message`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();

    setVideoData(data?.data);
  };

  useEffect(() => {
    fetchVideo();
  }, []);

  console.log(videoData);
  return (
    videoData &&
    videoData?.length > 0 && (
      <Grid
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid
          style={{
            width: "48%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography
            style={{
              fontSize: 12,
              fontWeight: "800",
              textAlign: "start",
              color: "rgb(0,96,170)",
            }}
          >
            {videoData[0]?.attributes?.metaData?.title}
          </Typography>
          <Typography style={{ fontSize: 10, textAlign: "start" }}>
            {videoData[0]?.attributes?.metaData?.body}
          </Typography>
        </Grid>
        <Grid style={{ width: "48%" }}>
          <img
            src={SITE_URL + videoData[0]?.attributes?.resourceFileUrl}
            style={{ width: "100%" }}
          />
          <Typography
            style={{
              fontWeight: "800",
              fontSize: 12,
              color: "rgb(0,96,170)",
              marginTop: 10,
            }}
          >
            {videoData[0]?.attributes?.name}
          </Typography>
        </Grid>
      </Grid>
    )
  );
};
