import { Grid, Typography } from "@mui/material";
import React from "react";
import DefaultUserImage from "../../../Assets/default_profile_pic.png";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import PhotoIcon from "@mui/icons-material/Photo";
import EmergencyRecordingIcon from "@mui/icons-material/EmergencyRecording";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { OC_ID, SITE_URL } from "../../../config";

// const SITE_URL = "https://api.sociana.in/";

const ChatroomCard = ({ chatroom, onPress, onPressForRejectModel }) => {
  const getFileType = (url) => {
    const extension = url?.split(".").pop().toLowerCase();
    const imageExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
      "svg",
      "tiff",
      "webp",
    ];
    const videoExtensions = [
      "mp4",
      "avi",
      "mov",
      "mkv",
      "wmv",
      "flv",
      "m4v",
      "3gp",
    ];

    if (imageExtensions.includes(extension)) {
      return "image";
    } else if (videoExtensions.includes(extension)) {
      return "video";
    } else {
      return "file";
    }
  };

  const getUserFriendlyTimeDuration = (dateString) => {
    const diffTime = new Date().getTime() - new Date(dateString).getTime();

    if (diffTime < 60 * 1000) {
      return "just now";
    }
    if (diffTime >= 60 * 1000 && diffTime < 60 * 60 * 1000) {
      return Math.floor(diffTime / (1000 * 60)) === 1
        ? "1 min ago"
        : `${Math.floor(diffTime / (1000 * 60))} minutes ago`;
    }
    if (diffTime >= 60 * 60 * 1000 && diffTime < 24 * 60 * 60 * 1000) {
      return Math.floor(diffTime / (1000 * 60 * 60)) === 1
        ? "1 hour ago"
        : `${Math.floor(diffTime / (1000 * 60 * 60))} hours ago`;
    }
    if (
      diffTime >= 24 * 60 * 60 * 1000 &&
      diffTime < 30 * 24 * 60 * 60 * 1000
    ) {
      return Math.floor(diffTime / (24 * 60 * 60 * 1000)) === 1
        ? "1 day ago"
        : `${Math.floor(diffTime / (24 * 60 * 60 * 1000))} days ago`;
    }
    if (
      diffTime >= 30 * 24 * 60 * 60 * 1000 &&
      diffTime < 12 * 30 * 24 * 60 * 60 * 1000
    ) {
      return Math.floor(diffTime / (30 * 24 * 60 * 60 * 1000)) === 1
        ? "1 month ago"
        : `${Math.floor(diffTime / (30 * 24 * 60 * 60 * 1000))} months ago`;
    }
    if (diffTime >= 12 * 30 * 24 * 60 * 60 * 1000) {
      return Math.floor(diffTime / (12 * 30 * 24 * 60 * 60 * 1000)) === 1
        ? "1 year ago"
        : `${Math.floor(diffTime / (12 * 30 * 24 * 60 * 60 * 1000))} years ago`;
    }
  };
  return (
    <button
      style={{
        width: "100%",
        paddingTop: 10,
        paddingBottom: 10,
        border: "none",
        borderBottom: "0.5px solid #ffffff",
        // borderBottomColor: "#ffffff",
        background: "transparent",
      }}
      onClick={() =>
        onPress(
          chatroom.id,
          chatroom.attributes?.otherUserData.name,
          chatroom.attributes?.otherUserData?.avatarUrl,
          chatroom.attributes?.otherUserData.id,
          chatroom.attributes?.createdById,
          chatroom.attributes?.isConversationAccepted,
          chatroom.attributes?.otherUserData?.email,
          chatroom.attributes?.otherUserData?.mobile
        )
      }
    >
      <Grid
        style={{
          width: "90%",
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          paddingTop: "20px",
        }}
      >
        {chatroom.attributes?.otherUserData?.avatarUrl ? (
          <Grid
            style={{
              width: "40px",
              height: "40px",
              borderRadius: 20,
              overflow: "hidden",
              marginRight: 10,
              // marginTop: 20,
            }}
          >
            <img
              src={`${SITE_URL}${chatroom.attributes?.otherUserData?.avatarUrl}`}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              alt="Null"
            />
          </Grid>
        ) : (
          <Grid
            style={{
              width: "40px",
              height: "40px",
              borderRadius: 20,
              overflow: "hidden",
              marginRight: 10,
              // marginTop: 20,
            }}
          >
            <img
              src={DefaultUserImage}
              alt="Null"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </Grid>
        )}
        <Grid
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyItems: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              maxWidth: 250,
              marginBottom: "5px",
              color: "#ffffff",
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
            }}
          >
            {chatroom.attributes?.otherUserData?.name}
          </Typography>
          <Grid
            style={{
              width: "100%",
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <span style={{ marginRight: "5px" }}>
              {chatroom.attributes?.latestMessage?.isRead ? (
                <DoneAllIcon style={{ fontSize: 14, color: "blue" }} />
              ) : (
                <DoneIcon style={{ color: "#ffffff", fontSize: 14 }} />
              )}
            </span>
            <span style={{ marginRight: "3px" }}>
              {!chatroom.attributes?.latestMessage
                ?.messageMediaUrl ? null : getFileType(
                  chatroom.attributes?.latestMessage?.messageMediaUrl
                ) === "image" ? (
                <PhotoIcon style={{ fontSize: 14, color: "#ffffff" }} />
              ) : getFileType(
                  chatroom.attributes?.latestMessage?.messageMediaUrl
                ) === "video" ? (
                <EmergencyRecordingIcon
                  style={{ fontSize: 14, color: "#ffffff" }}
                />
              ) : (
                <InsertDriveFileIcon
                  style={{ fontSize: 14, color: "#ffffff" }}
                />
              )}
            </span>
            <Typography
              variant="body2"
              style={{
                width: "140px",
                color: "#ffffff",
                textAlign: "start",
              }}
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {chatroom.attributes?.latestMessage?.messageContent &&
                chatroom.attributes?.latestMessage?.messageContent}
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="caption"
          style={{
            color: "#ffffff",
            position: "absolute",
            top: 2,
            right:
              chatroom.attributes?.createdById ===
                chatroom.attributes?.otherUserData?.id &&
              chatroom.attributes?.isConversationAccepted
                ? "20px"
                : "5px",
          }}
        >
          {getUserFriendlyTimeDuration(
            chatroom.attributes?.latestMessage?.createdAt
          )}
        </Typography>
        {chatroom.attributes?.createdById ===
          chatroom.attributes?.otherUserData?.id &&
          chatroom.attributes?.isConversationAccepted && (
            <button
              style={{
                width: "5px",
                height: "10px",
                position: "absolute",
                top: 2,
                right: "5px",
                border: "none",
                background: "transparent",
              }}
              onClick={(event) => {
                event.stopPropagation();
                onPressForRejectModel(chatroom.id);
              }}
            >
              <MoreVertIcon style={{ fontSize: 25, color: "#ffffff" }} />
            </button>
          )}
        {chatroom.attributes?.isUnreadMessages ? (
          <div
            style={{
              width: "15px",
              height: "15px",
              borderRadius: 7.5,
              backgroundColor: "rgba(244, 108, 34, 1)",
              position: "absolute",
              bottom: 10,
              right: 25,
            }}
          ></div>
        ) : null}
      </Grid>
    </button>
  );
};

export default ChatroomCard;
