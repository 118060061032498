import { Grid, Typography } from "@mui/material";
import React from "react";
import toprightLogo from "../Assets/eil/logo.png";

const Header = () => {
  let userName = localStorage.getItem("currentUserName");
  return (
    <Grid
      style={{
        width: "100%",
        background: "rgb(80,112,143)",
        padding: "0px 20px 0px 20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "0px 0px 25px 25px",
        boxShadow: "0 0 10px rrgba(80,112,143,0.5)",
      }}
    >
      <Grid
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "10px 0px",
        }}
      >
        <Typography variant="body2" style={{ color: "#ffffff" }}>
          Welcome
        </Typography>
        {userName && (
          <Typography
            variant="h6"
            style={{
              color: "#ffffff",
              textTransform: "capitalize",
              fontWeight: "600",
            }}
          >
            {userName.split(" ")[0]}{" "}
          </Typography>
        )}
      </Grid>
      <Grid
        style={{
          width: 50,
          height: 50,
          borderRadius: 25,
          backgroundColor: "#ffffff",
        }}
      >
        <img src={toprightLogo} style={{ width: 50, objectFit: "contain" }} />
        {/* <img src={toprightImage} style={{ width: 120 }} /> */}
      </Grid>
      <Grid></Grid>
    </Grid>
  );
};

export default Header;
