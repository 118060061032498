import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./pool.css";
import { OC_ID, SITE_URL } from "../../config";

const Pollone = ({ currentData, included, token }) => {
  const [apiSelectedId, setApiSelectedId] = useState({
    data: [],
    included: [],
  });
  const [selecedOptionID, setSelecedOptionID] = useState();
  const [eachOptVoteArray, setEachOptVoteArray] = useState(0);

  useEffect(() => {
    setEachOptVoteArray(
      currentData?.relationships?.pollPostOptions?.data
        ?.map((el) => {
          return included?.find((elem) => {
            return el.id == elem.id;
          })?.attributes?.answerCount;
        })
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0)
    );
  }, [selecedOptionID]);

  const handleSelect = (el) => {
    if (selecedOptionID == el) {
      return "pollselected";
    }
  };

  const handleCheck = (id) => {
    let formdata = new FormData();
    formdata.append("poll_post_answer[poll_post_option_id]", id);
    fetch(
      `${SITE_URL}/api/version/v2/oc/${OC_ID}/visitant/poll_post/poll-question-responses`,
      {
        method: "POST",
        body: formdata,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res) =>
      res.json().then((result) => {
        if (result) {
          setApiSelectedId({
            data: result?.data,
            included: result?.included,
          });
        }
      })
    );
    setSelecedOptionID(id);
  };

  return (
    <Grid
      key={currentData.id}
      style={{
        minwidth: "300px",
        maxWidth: "500px",
        margin: "auto",
        padding: "14px 20px",
        background: "#ffffff",
        boxShadow: "2.5px 2.5px 2px 1.5px rgba(95, 188, 84, 1)",
        borderRadius: 15,
      }}
    >
      <Grid style={{ marginBottom: "10px" }}>
        <Typography
          style={{
            width: "100%",
            fontSize: "18px",
            color: "#000000",
            fontWeight: "500",
            fontFamily: '"Roboto Condensed", sans-serif',
            lineHeight: "22px",
          }}
        >
          {currentData?.attributes?.question}
        </Typography>
      </Grid>
      <Grid className="options">
        <Grid className="pooloptions">
          {currentData?.relationships?.pollPostOptions?.data?.map((el) => {
            return (
              <button
                onClick={() => handleCheck(el?.id)}
                key={el.id}
                className={
                  currentData?.relationships?.currentUserPollPostAnswer?.data
                    ?.id
                    ? el.id ==
                      included?.find(
                        (elem) =>
                          elem.id ==
                            currentData?.relationships
                              ?.currentUserPollPostAnswer?.data?.id &&
                          elem.type ==
                            currentData?.relationships
                              ?.currentUserPollPostAnswer?.data?.type
                      )?.attributes?.pollPostOptionId
                      ? "pollselected"
                      : "singleOptionpool"
                    : `singleOptionpool ${
                        selecedOptionID && handleSelect(el.id)
                      }`
                }
                disabled={
                  currentData?.relationships?.currentUserPollPostAnswer?.data
                    ?.id
                    ? true
                    : apiSelectedId?.data?.relationships
                        ?.currentUserPollPostAnswer?.data?.id
                    ? true
                    : false
                }
              >
                {Math.floor(
                  (included?.find(
                    (elem) => elem.id == el.id && elem.type == el.type
                  )?.attributes?.answerCount *
                    100) /
                    eachOptVoteArray
                ) > 0
                  ? `${Math.floor(
                      (included?.find(
                        (elem) => elem.id == el.id && elem.type == el.type
                      )?.attributes?.answerCount *
                        100) /
                        eachOptVoteArray
                    )}%    ${
                      included?.find(
                        (elem) => elem.id == el.id && elem.type == el.type
                      )?.attributes?.option
                    }`
                  : included?.find(
                      (elem) => elem.id == el.id && elem.type == el.type
                    )?.attributes?.option}
              </button>
            );
          })}
        </Grid>
      </Grid>
      <Grid>
        {eachOptVoteArray > 0 ? (
          <Typography
            variant="h6"
            style={{
              color: "#000000",
              fontFamily: "'Roboto Condensed', sans-serif",
            }}
          >{`${eachOptVoteArray} votes`}</Typography>
        ) : (
          <Typography
            variant="h6"
            style={{
              color: "#000000",
              fontFamily: "'Roboto Condensed', sans-serif",
            }}
          >
            0 votes
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default Pollone;
