import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import QALogo from "../../Assets/homelogo.jpeg";
import leftArrow from "../../Assets/AgendaIcon.png";
import EmptyData from "../../Components/EmptyData";
import "./noti.css";
import { OC_ID, SITE_URL } from "../../config";
import Header from "../../Components/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Notification = () => {
  let token = localStorage.getItem("token");
  const [notificationData, setNotificationData] = useState({
    data: [],
    links: {},
  });
  const [page, setPage] = React.useState(1);

  const navigate = useNavigate();

  const onclicked = () => {
    navigate("/");
  };

  useEffect(() => {
    // console.log("page",page)
    try {
      fetch(
        `${SITE_URL}/api/version/v1/oc/${OC_ID}/occasion-notifications?page=${page}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((results) => {
          if (results) {
            setNotificationData({
              data: notificationData?.data.concat(results?.data),
              links: results?.links,
            });
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [page]);

  console.log(notificationData.data);

  const getNextPage = (str) => {
    const str1 = str.split("=");
    const res = str1[1].split("&");
    return parseInt(res[0], 10);
  };

  const onLoadMore = () => {
    if (!!notificationData?.links?.next) {
      const pageNumber = getNextPage(notificationData?.links?.next);
      setPage(pageNumber);
    }
  };

  const loadMore = React.useMemo(() => {
    return !!notificationData?.links?.next ? (
      <Grid textAlign="center" style={{ width: "100%" }}>
        <Button
          style={{
            marginBottom: "20px",
            cursor: "pointer",
            padding: "5px 20px",
            backgroundColor: "rgba(95, 188, 84, 1)",
            color: "#000000",
            borderRadius: "10px",
          }}
          onClick={onLoadMore}
        >
          loading more
        </Button>
      </Grid>
    ) : null;
  }, [notificationData?.links?.next]);

  return (
    <Grid container style={{ marginBottom: "60px" }}>
      <Header />
      <Grid
        style={{
          width: "100%",
          padding: "10px 15px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={onclicked}
          style={{
            padding: "10px 10px",
            textTransform: "none",
            background: "none",
            color: "#000000",
            fontSize: "25px",
            fontWeight: "500",
            fontFamily: "'Roboto Condensed', sans-serif",
          }}
        >
          <ArrowBackIcon style={{ color: "#000000", marginRight: 5 }} />
          <b style={{ color: "#000000" }}>Notification</b>
        </Button>
      </Grid>
      <Grid
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 10px",
        }}
      >
        {notificationData?.data?.length > 0 ? (
          <>
            {notificationData?.data?.map((el) => {
              return (
                <Grid className="notifi_card">
                  <Typography style={{ marginBottom: "5px" }}>
                    <span
                      style={{
                        background: "#ffffff",
                        color: "rgb(0,96,170)",
                        padding: "5px 10px",
                        borderRadius: "8px",
                      }}
                    >
                      {new Date(el?.attributes?.createdAt).toDateString()}
                    </span>
                  </Typography>
                  <Typography style={{ paddingLeft: "10px" }}>
                    {el?.attributes?.message}
                  </Typography>
                </Grid>
              );
            })}
            {loadMore}
          </>
        ) : (
          <EmptyData color="#ffffff" />
        )}
      </Grid>
    </Grid>
  );
};

export default Notification;

// ({
//   notificationData?.data?.map(el=>{
//     <Grid style={{width:"500px", margin:"10px auto",background:"whitesmoke",color:"black",padding:"10px 10px",borderRadius:"8px"}}>
//       <Typography>
//         {new Date(el.createdAt).toDateString()}
//       </Typography>
//     <Typography>
//       {el.message}
//     </Typography>
//   </Grid>
//   })
//   {loadMore}
// })
