import React, { useEffect, useState } from "react";
import "./leaderboard.css";
import { useNavigate } from "react-router-dom";
import { Grid, Button, Typography, CircularProgress } from "@mui/material";

import Header from "../../Components/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { OC_ID, SITE_URL } from "../../config";
import EmptyDataLeaderboard from "../../Components/EmptyDataLeaderboard";

const Leaderboards = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [tournamentdata, setTournamentdata] = useState(null);
  const [leaderboardData, setLeaderboardData] = useState({
    data: [],
    currentUserData: null,
  });
  const [contestId, setContestId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isScoreShow, setIsScoreShow] = useState(null);
  const [tournamentLoading, setTournamentLoading] = useState(false);

  const onclicked = () => {
    navigate("/");
  };

  useEffect(() => {
    try {
      setTournamentLoading(true);
      fetch(
        `${SITE_URL}/api/version/v2/oc/${OC_ID}/visitant/tournament/fetch-tournament-details-by-name?responser=true&name=Pluxee_2025`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          setTournamentLoading(false);
          return res.json();
        })
        .then((result) => {
          if (result?.data) {
            setTournamentdata([result.data]);
            setTournamentLoading(false);
          }
        });
    } catch (error) {
      console.log(error);
      setTournamentLoading(false);
    }
  }, []);

  useEffect(() => {
    if (
      tournamentdata &&
      tournamentdata.length > 0 &&
      tournamentdata[0]?.contests?.length > 0
    ) {
      setContestId(tournamentdata[0]?.contests[0]?.id);
    }
  }, [tournamentdata]);

  const fetchContest = (contestId) => {
    try {
      setLoading(true);
      fetch(
        `${SITE_URL}/api/version/v2/oc/${OC_ID}/visitant/tournament/contests/${contestId}/view-contest-scores?responser=true`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result?.data) {
            setLoading(false);
            setLeaderboardData({
              data: result?.data || [],
              currentUserData: result?.meta?.current_user_score_details || null,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (contestId) {
      fetchContest(contestId);
    }
  }, [contestId]);

  useEffect(() => {
    if (
      tournamentdata &&
      tournamentdata.length > 0 &&
      tournamentdata[0]?.contests?.length > 0
    ) {
      const scoreShow = tournamentdata[0]?.contests.find(
        (el) => el.id == contestId
      );
      setIsScoreShow(scoreShow);
    }
  }, [tournamentdata, contestId]);

  const ScoreboardItemList = ({ data, index }) => {
    const isScoreShow =
      tournamentdata &&
      tournamentdata.length > 0 &&
      tournamentdata[0]?.contests?.length > 0 &&
      tournamentdata[0]?.contests.find((el) => el.id == contestId);

    return (
      <Grid
        key={index}
        sx={{
          width: "100%",
          padding: "10px 30px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottomColor: "rgba(217, 217, 217, 1)",
          borderBottomWidth: 1,
        }}
      >
        <Grid
          sx={{
            width: "70%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Grid
            sx={{
              width: 35,
              height: 35,
              minWidth: "35px",
              borderRadius: 17.5,
              marginRight: "10px",
              backgroundColor: "rgba(0, 150, 54, 1)",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#ffffff",
                fontSize: 16,
                fontWeight: "800",
              }}
            >
              {data?.rank}
            </Typography>
          </Grid>
          <Typography
            sx={{
              color: "rgba(11, 49, 94, 1)",
              fontSize: 16,
              fontWeight: "600",
            }}
          >
            {data?.userName}
          </Typography>
        </Grid>
        <Grid
          sx={{
            width: "28%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {isScoreShow.description === "false" ? null : (
            <Typography
              sx={{
                color: "rgba(11, 49, 94, 1)",
                fontSize: 16,
                fontWeight: "600",
              }}
            >
              {data?.totalScore}
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container style={{ marginBottom: "60px" }}>
      <Header />
      <Grid
        style={{
          width: "100%",
          padding: "10px 15px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={onclicked}
          style={{
            padding: "10px 10px",
            textTransform: "none",
            background: "none",
            color: "#ffffff",
            fontSize: "25px",
            fontWeight: "500",
            fontFamily: "'Roboto Condensed', sans-serif",
          }}
        >
          <ArrowBackIcon style={{ color: "#ffffff", marginRight: 5 }} />
          <b style={{ color: "#ffffff" }}>Leaderboard</b>
        </Button>
      </Grid>
      {!tournamentLoading ? (
        <Grid
          style={{
            width: "100%",
            maxWidth: "500px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 10px",
            margin: "auto",
          }}
        >
          <Grid
            style={{
              width: "100%",
              backgroundColor: "rgba(107, 155, 165, 0.56)",
              overflowX: "scroll",
              paddingTop: "5px",
              whiteSpace: "nowrap",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Grid
              sx={{
                width: "auto",
                height: 60,
                gap: "5px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              {tournamentdata &&
              tournamentdata?.length > 0 &&
              tournamentdata[0]?.contests?.length > 0 &&
              contestId
                ? tournamentdata[0]?.contests?.map((contest, index) => (
                    <Button
                      key={index}
                      sx={{
                        padding: "0px 10px",
                        color: "#fff",
                        fontSize: "12px",
                        display: "inline-block",
                        borderBottomWidth: contestId == contest?.id ? 4 : 0,
                        borderBottomStyle: "solid",
                        borderBottomColor:
                          contestId == contest?.id
                            ? "yellow"
                            : "rgba(107, 155, 165, 0.56)",
                      }}
                      onClick={() => setContestId(contest?.id)}
                    >
                      {contest?.name}
                    </Button>
                  ))
                : null}
            </Grid>
          </Grid>
          {!loading ? (
            <>
              {leaderboardData && leaderboardData.data.length > 0 ? (
                <Grid
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 100,
                    backgroundColor: "#ffffff",
                  }}
                >
                  {leaderboardData?.currentUserData &&
                    Object.keys(leaderboardData?.currentUserData).length > 0 &&
                    leaderboardData?.currentUserData?.rank && (
                      <Grid
                        style={{
                          width: "100%",
                          height: 50,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: -25,
                          zIndex: 5,
                        }}
                      >
                        <Typography
                          style={{
                            width: 150,
                            paddingVertical: 5,
                            borderRadius: 25,
                            backgroundColor: "rgba(4, 97, 128, 1)",
                            textAlign: "center",
                            color: "#ffffff",
                            fontSize: 16,
                            fontWeight: "800",
                          }}
                        >
                          Your rank : {leaderboardData?.currentUserData?.rank}
                        </Typography>
                      </Grid>
                    )}
                  {leaderboardData?.currentUserData &&
                    Object.keys(leaderboardData?.currentUserData).length >
                      0 && (
                      <Grid
                        sx={{
                          width: "94%",
                          padding: "10px 20px",
                          borderRadius: 12,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "rgba(0, 150, 54, 1)",
                          zIndex: 3,
                          margin: "0 3%",
                        }}
                      >
                        <Grid
                          sx={{
                            width: "70%",
                            display: "flex",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            sx={{
                              width: 35,
                              height: 35,
                              minWidth: "35px",
                              borderRadius: 17.5,
                              backgroundColor: "white",
                              overflow: "hidden",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: "10px",
                              borderWidth: 2,
                              borderColor: "#ffffff",
                            }}
                          >
                            {leaderboardData.currentUserData?.userAvatar ? (
                              <img
                                src={`${SITE_URL}${leaderboardData.currentUserData?.userAvatar}`}
                                alt="Null"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            ) : (
                              <Typography
                                style={{
                                  textAlign: "center",
                                  color: "#ffffff",
                                  fontWeight: "700",
                                  fontSize: 16,
                                }}
                              >
                                {leaderboardData.currentUserData?.userName.substring(
                                  0,
                                  1
                                )}
                              </Typography>
                            )}
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                color: "#ffffff",
                                fontSize: 16,
                                fontWeight: "800",
                              }}
                            >
                              {leaderboardData.currentUserData?.userName}
                            </Typography>
                            <Typography
                              style={{
                                color: "#ffffff",
                                fontSize: "9px",
                                fontWeight: "100",
                              }}
                            >
                              {leaderboardData.currentUserData?.userEmail}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          style={{
                            width: "28%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          {isScoreShow.description === "false" ? null : (
                            <Typography
                              style={{
                                color: "#ffffff",
                                fontSize: 16,
                                fontWeight: "800",
                              }}
                            >
                              {leaderboardData.currentUserData?.totalScore}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  <Grid
                  // sx={{
                  //   maxHeight: "auto",
                  //   overflowY: "scroll",
                  // }}
                  >
                    {leaderboardData &&
                      leaderboardData.data.length > 0 &&
                      leaderboardData.data.map((el, index) => (
                        <>
                          <ScoreboardItemList
                            key={index}
                            data={el}
                            index={index}
                          />
                        </>
                      ))}
                  </Grid>
                </Grid>
              ) : (
                <Typography
                  style={{
                    height: "200px",
                    color: "#ffffff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <EmptyDataLeaderboard />
                </Typography>
              )}
            </>
          ) : (
            <Typography
              style={{
                height: "200px",
                color: "#ffffff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Typography>
          )}
        </Grid>
      ) : (
        <Grid
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 10px",
          }}
        >
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
};

export default Leaderboards;
