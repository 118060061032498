import { Grid } from "@mui/material";
import React from "react";

const SearchBar = ({ searchQuery, onSearch, placeholder, setSearchQuery }) => {
  const handleChangeText = (text) => {
    setSearchQuery(text);
    onSearch(text);
  };
  return (
    <Grid
      style={{
        width: "100%",
        padding: 5,
        paddingBottom: 7,
        position: "sticky",
        top: 0,
        left: 5,
        right: 5,
        background: "rgb(0,96,170)",
        zIndex: 999,
      }}
    >
      <input
        style={{
          width: "100%",
          height: 40,
          padding: "5px",
          background: "#E3E3E3",
          borderRadius: 5,
          border: "none",
          outline: "none",
          boxShadow: "0px 0px 2px 1px #5C2C5C",
        }}
        placeholder={placeholder ? placeholder : "Search..."}
        value={searchQuery}
        onChange={(e) => handleChangeText(e.target.value)}
      />
    </Grid>
  );
};

export default SearchBar;
