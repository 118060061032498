import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EmptyData from "../../Components/EmptyData";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import "./style.css";
import { OC_ID, SITE_URL } from "../../config";
import Header from "../../Components/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Sos = () => {
  let token = localStorage.getItem("token");
  const [sosData, setSosData] = useState({
    data: [],
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onclicked = () => {
    navigate("/");
  };

  useEffect(() => {
    try {
      setLoading(true);
      fetch(
        `${SITE_URL}/api/version/v1/oc/${OC_ID}/fetch-occasion-assets-by-category?resource_category=helpline`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          setLoading(false);
          return res.json();
        })
        .then((results) => {
          if (results) {
            setSosData({
              data: results?.data,
            });
            setLoading(false);
          }
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, []);

  return (
    <Grid container style={{ marginBottom: "60px" }}>
      <Header />
      <Grid
        style={{
          width: "100%",
          padding: "10px 15px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={onclicked}
          style={{
            padding: "10px 10px 10px 5px",
            textTransform: "none",
            background: "none",
            color: "#ffffff",
            fontSize: "25px",
            fontWeight: "500",
            fontFamily: "'Roboto Condensed', sans-serif",
          }}
        >
          <ArrowBackIcon
            style={{ color: "rgba(16, 54, 104, 1)", marginRight: 10 }}
          />

          <b style={{ color: "rgba(16, 54, 104, 1)" }}>Help Desk</b>
        </Button>
      </Grid>
      {!loading ? (
        <Grid
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 10px",
          }}
        >
          {sosData?.data?.length > 0 ? (
            <>
              {sosData?.data?.map((el) => {
                return (
                  <Grid className="notifi_card">
                    <a
                      href={el?.attributes?.link}
                      target="_blank"
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                    >
                      <Typography
                        style={{
                          marginBottom: "5px",
                          color: "#ffffff",
                          textAlign: "center",
                          fontWeight: "800",
                        }}
                      >
                        {el?.attributes?.name}
                      </Typography>
                      <Typography
                        style={{
                          textAlign: "center",
                          fontWeight: "600",
                          color: "#ffffff",
                        }}
                      >
                        {el?.attributes?.metaData?.phone}
                      </Typography>
                    </a>
                  </Grid>
                );
              })}
            </>
          ) : (
            <EmptyData color="#ffffff" />
          )}
        </Grid>
      ) : (
        <Grid
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 10px",
          }}
        >
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
};

export default Sos;
