import React, { useEffect, useState } from "react";
import "./login.css";
import Logo from "../../Assets/eil/appimage.jpeg";
import toprightLogo from "../../Assets/eil/logo.png";
import bottomImage from "../../Assets/eil/waterbg.png";
import {
  Grid,
  Typography,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import Btn from "../../Components/Button";
import LoginOtp from "./LoginOtp";
import { validateEmail, validateMobile } from "../../Components/Validation";
import { useNavigate } from "react-router-dom";
import { SITE_URL } from "../../config";

const validateLoginData = (values) => {
  const error = {};
  if (!values.loginData) {
    error.loginData = "Email / Phone is required";
    return false;
  } else if (
    values.loginData &&
    (validateEmail(values.loginData) || validateMobile(values.loginData))
  ) {
    error.loginData = "";
    return true;
  } else {
    error.loginData = "Invalid Phone No or Email";
    return false;
  }
};

const PERSONALIZED_APP_ID =
  "44df7cef5e9d45deecc440fa21f5a13d30e3fdc3419042f5e6273e8bc8844a0fb97d9800fd0906478943f94603e4171bd16e913dfcef97c9b14be051851674b3";

const Login = () => {
  const [isVerificationOpen, setIsVerificationOpen] = useState(false);
  const [loginFormData, setLoginFormData] = useState({ loginData: "" });
  const [errorMessages, setErrorMessages] = useState({});
  const [snackbarData, setSnackbarData] = useState({
    message: "",
    type: "success",
  });
  const [loading, setLoading] = useState(false);
  const [otpData, setOtpData] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    let login = localStorage.getItem("login");
    if (login) {
      navigate("/");
    }
  });

  const handleChange = (e) => {
    try {
      const LoginValue = e?.target?.value;
      const inputName = e?.target?.name;
      if (Object.keys(errorMessages).length > 0) {
        setErrorMessages({});
      }
      setLoginFormData({
        ...loginFormData,
        [inputName]: LoginValue,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleContinue = () => {
    try {
      const validateObj = validateLoginData(loginFormData);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        setLoading(true);

        const body = {
          verification: {
            custom_app_id: PERSONALIZED_APP_ID,
          },
        };
        if (validateEmail(loginFormData.loginData)) {
          console.log("email");
          body.verification["email"] = loginFormData.loginData;
        }
        if (validateMobile(loginFormData.loginData)) {
          console.log("mobile");
          body.verification["phone"] = loginFormData.loginData;
        }

        fetch(`${SITE_URL}/api/version/v3/generate-authentication-otp`, {
          method: "POST",
          body: JSON.stringify(body),
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => {
            if (response.status == 200 || response.status == 201) {
              return (
                setLoading(false),
                setSnackbarData({
                  message: "OTP Send Successfully.",
                  type: "success",
                }),
                setIsVerificationOpen(true),
                response.json()
              );
            }
            return (
              setLoading(false),
              setSnackbarData({
                message: "Something Went Wrong.",
                type: "error",
              }),
              response.json()
            );
          })
          .then((results) => {
            setLoading(false);
            setOtpData({ ...results?.data?.attributes });
          });
      }
    } catch (error) {
      setLoading(false);
      setSnackbarData({
        message: "Something Went Wrong.",
        type: "error",
      });
      console.error(error);
    }
  };

  const handleClose = () => {
    setSnackbarData({
      message: "",
      type: "success",
    });
  };

  const backLoginPage = () => {
    setIsVerificationOpen(false);
  };

  // console.log("chandan", otpData);

  return (
    <>
      {isVerificationOpen ? (
        <LoginOtp
          onclicked={backLoginPage}
          loginFormData={loginFormData}
          otpData={otpData}
          PERSONALIZED_APP_ID={PERSONALIZED_APP_ID}
        />
      ) : (
        <Grid
          container
          style={{ maxWidth: 350, height: "100vh", margin: "0 auto" }}
        >
          <Grid style={{ width: "100%", textAlign: "center" }}>
            <Grid
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid style={{ width: "100%" }}>
                <img
                  style={{ width: "100%", objectFit: "fill" }}
                  src={Logo}
                  alt="Logo"
                />
              </Grid>
              <Grid
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 15,
                }}
              >
                <img style={{ width: 100 }} src={toprightLogo} alt="Logo" />
              </Grid>
            </Grid>
            <Grid className="login_data">
              <Grid style={{ textAlign: "start", marginTop: "5px" }}>
                <Typography
                  variant="subtitle1"
                  style={{ color: "rgb(0,96,170)" }}
                >
                  Let’s start with your <b>Email / Phone</b>
                </Typography>
                <Box
                  component="form"
                  sx={{
                    "& > :not(style)": {
                      width: "100%",
                      background: "#ffffff",
                      borderRadius: "4px",
                      margin: "10px 0 3px 0",
                    },
                  }}
                >
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    onChange={handleChange}
                    value={loginFormData.loginData}
                    name="loginData"
                  />
                </Box>
                <Typography variant="subtitle" style={{ color: "red" }}>
                  {errorMessages.loginData}
                </Typography>
              </Grid>
              <Btn
                btnName="Log In"
                onclicked={handleContinue}
                loading={loading}
              />
            </Grid>
            <Grid>
              <img src={bottomImage} style={{ width: "100%" }} />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Snackbar
        open={!!snackbarData.message}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
        style={{ border: "2px solid black", borderRadius: 5 }}
        message={snackbarData.message}
      >
        <Alert severity={snackbarData.type}>{snackbarData.message}</Alert>
      </Snackbar>
    </>
  );
};

export default Login;
