import { Grid, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

const RejectUserModel = ({ closeRejectUserModel, updateChatroom }) => {
  return (
    <Grid
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0,0,0,0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        style={{
          width: "300px",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 0px 3px #ffffff",
          padding: "15px 20px",
          background: "#1A1028",
          position: "relative",
          borderRadius: 5,
        }}
      >
        <CloseIcon
          style={{
            width: "24px",
            height: "24px",
            background: "#5C2C5C",
            color: "#ffffff",
            fontSize: 20,
            position: "absolute",
            top: 2,
            right: 2,
            borderRadius: 12,
            cursor: "pointer",
            padding: 5,
          }}
          onClick={() => closeRejectUserModel()}
        />
        <Typography
          variant="body2"
          style={{
            textAlign: "center",
            marginBottom: "15px",
            color: "#ffffff",
          }}
        >
          Click "Reject" to discontinue this conversation.
        </Typography>
        <button
          onClick={() => updateChatroom()}
          style={{
            background: "#ffffff",
            color: "#000000",
            borderRadius: 5,
            border: "none",
            padding: 8,
            cursor: "pointer",
          }}
        >
          Reject
        </button>
      </Grid>
    </Grid>
  );
};

export default RejectUserModel;
