import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import QALogo from "../../Assets/homelogo.jpeg";
import leftArrow from "../../Assets/AgendaIcon.png";
// import CircularProgress from '@mui/material/CircularProgress';
import Pollone from "./Pollone";
import { OC_ID, SITE_URL } from "../../config";
import Header from "../../Components/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Livepolls = () => {
  let token = localStorage.getItem("token");
  const [questionData, setQuestionData] = useState({
    data: [],
    included: [],
    links: {},
    meta: {},
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(1);

  const navigate = useNavigate();

  const onclicked = () => {
    navigate("/");
  };

  useEffect(() => {
    // console.log("page",page)
    try {
      setLoading(true);
      fetch(
        `${SITE_URL}/api/version/v2/oc/${OC_ID}/visitant/poll_post/fetch-posts-by-poll-question-type?page=${page}&question_type=poll`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          setLoading(false);
          return res.json();
        })
        .then((results) => {
          if (results) {
            setQuestionData({
              data: questionData?.data.concat(results?.data),
              included: questionData?.included.concat(results?.included),
              links: results?.links,
              meta: results?.meta,
            });
            setLoading(false);
          }
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [page]);

  const getNextPage = (str) => {
    const str1 = str.split("=");
    const res = str1[1].split("&");
    return parseInt(res[0], 10);
  };

  const onLoadMore = () => {
    if (!!questionData?.links?.next) {
      const pageNumber = getNextPage(questionData?.links?.next);
      setPage(pageNumber);
    }
  };

  const loadMore = React.useMemo(() => {
    return !!questionData?.links?.next ? (
      <Grid textAlign="center" style={{ width: "100%" }}>
        <Button
          style={{
            marginBottom: "20px",
            cursor: "pointer",
            padding: "5px 20px",
            backgroundColor: "rgba(95, 188, 84, 1)",
            color: "#000000",
            borderRadius: "10px",
          }}
          onClick={onLoadMore}
        >
          loading more
        </Button>
      </Grid>
    ) : null;
  }, [questionData?.links?.next]);

  return (
    <Grid container style={{ marginBottom: "60px" }}>
      <Header />
      <Grid
        style={{
          width: "100%",
          padding: "10px 15px",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Button
          onClick={onclicked}
          style={{
            padding: "10px 10px 10px 5px",
            textTransform: "none",
            background: "none",
            color: "#ffffff",
            fontSize: "25px",
            fontWeight: "500",
            fontFamily: "'Roboto Condensed', sans-serif",
          }}
        >
          <ArrowBackIcon style={{ color: "#ffffff", marginRight: 10 }} />
          <b style={{ color: "#ffffff" }}>Live Polls</b>
        </Button>
      </Grid>
      {!loading ? (
        <>
          <Grid
            style={{ width: "100%", padding: "0px 10px", marginBottom: "30px" }}
          >
            {questionData?.data?.map((quedata) => (
              <>
                <Pollone
                  currentData={quedata}
                  included={questionData?.included}
                  token={token}
                />
                <Grid
                  style={{
                    minwidth: "300px",
                    maxWidth: "500px",
                    margin: "auto",
                  }}
                >
                  <Grid style={{ width: "80%", margin: "40px auto" }}>
                    <hr style={{ color: "white" }} />
                    <hr style={{ color: "white" }} />
                  </Grid>
                </Grid>
              </>
            ))}
          </Grid>
          {loadMore}
        </>
      ) : (
        <Grid
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 10px",
          }}
        >
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
};

export default Livepolls;
