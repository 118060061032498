import { CircularProgress, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DefaultUserImage from "../../../Assets/default_profile_pic.png";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import SelectFile from "./SelectFile";
import { OC_ID, SITE_URL } from "../../../config";
// const SITE_URL = "https://api.sociana.in/";

const NewUserModel = ({
  closeNewUserModel,
  otherUserUrl,
  otherUsername,
  otherUserid,
  setIsMediaModel,
  fetchChatrooms,
  token,
}) => {
  const [isActiveSelectIcon, setIsActiveSelectIcon] = useState(false);
  const [file, setFile] = useState(null);
  const [messageText, setMessageText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSelectIcon = () => {
    setIsActiveSelectIcon(!isActiveSelectIcon);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("chatroom_message[other_user_id]", otherUserid);
      if (messageText != "") {
        formData.append("chatroom_message[message_content]", messageText);
      }
      if (file) {
        formData.append("chatroom_message[message_media]", file);
      }

      const response = await fetch(
        `${SITE_URL}/api/version/v2/oc/${OC_ID}/visitant/chatroom/room-messages`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status == 200 || response.status == 201) {
        setLoading(false);
        setIsMediaModel(false);
        setMessageText("");
        setFile(null);
        closeNewUserModel();
        setIsActiveSelectIcon(false);
        await fetchChatrooms();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <Grid
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0,0,0,0.9)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
    >
      <Grid
        style={{
          width: "100%",
          maxWidth: "400px",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 0px 2px 1px rgb(2, 59, 102)",
          padding: "8px",
          background: "rgb(0,96,170)",
          position: "relative",
          borderRadius: 10,
        }}
      >
        <Grid
          style={{
            width: "100%",
            height: 60,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <ArrowBackIcon
            onClick={() => closeNewUserModel()}
            style={{ fontSize: 20, color: "#ffffff", marginRight: 5 }}
          />
          {otherUserUrl ? (
            <Grid
              style={{
                width: "30px",
                height: "30px",
                borderRadius: 15,
                overflow: "hidden",
                marginRight: 10,
                // marginTop: 20,
              }}
            >
              <img
                src={`${SITE_URL}${otherUserUrl}`}
                style={{ width: "100%", height: "100%" }}
                alt="Null"
              />
            </Grid>
          ) : (
            <Grid
              style={{
                width: "30px",
                height: "30px",
                borderRadius: 15,
                overflow: "hidden",
                marginRight: 10,
                // marginTop: 20,
              }}
            >
              <img
                src={DefaultUserImage}
                alt="Null"
                style={{ width: "100%", height: "100%" }}
              />
            </Grid>
          )}
          <button
            style={{
              color: "#ffffff",
              fontWeight: "700",
              border: "none",
              background: "transparent",
            }}
          >
            {otherUsername}
          </button>
        </Grid>
        <Grid style={{ width: "100%", padding: 5 }}>
          <Typography
            variant="body1"
            style={{ marginBottom: 10, color: "#ffffff" }}
          >
            Once {otherUsername} accepts your message, you will be able to
            initiate a chat with him.
          </Typography>
          <Typography variant="body1" style={{ color: "#ffffff" }}>
            Please send the message to submit your chat request.
          </Typography>
        </Grid>

        <Grid
          style={{
            width: "100%",
            height: 50,
            background: "#ffffff",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: 15,
            paddingRight: 15,
            borderRadius: "15px",
          }}
        >
          <span
            style={{
              background: "rgb(0,96,170)",
              padding: 6,
              width: "35px",
              height: "35px",
              borderRadius: 17.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: 10,
              cursor: "pointer",
            }}
          >
            {!isActiveSelectIcon ? (
              <AttachFileIcon
                style={{ fontSize: "24px", color: "#ffffff" }}
                onClick={() => handleSelectIcon()}
              />
            ) : (
              <CloseIcon
                style={{ fontSize: "24px", color: "#ffffff" }}
                onClick={() => handleSelectIcon()}
              />
            )}
          </span>
          <input
            type="text"
            placeholder="Message"
            style={{
              flex: 1,
              height: 35,
              borderRadius: 25,
              padding: 5,
              marginRight: 10,
              border: "1px solid rgb(0,96,170)",
              outline: "none",
            }}
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
          />
          {file || messageText != "" ? (
            loading ? (
              <CircularProgress />
            ) : (
              <SendIcon
                style={{
                  background: "rgb(0,96,170)",
                  color: "#ffffff",
                  padding: 5,
                  borderRadius: 25,
                  fontSize: 35,
                  cursor: "pointer",
                }}
                onClick={handleSubmit}
              />
            )
          ) : (
            <SendIcon
              style={{
                background: "rgb(0,96,170)",
                color: "#ffffff",
                padding: 5,
                borderRadius: 25,
                fontSize: 35,
                cursor: "not-allowed",
                opacity: 0.5,
              }}
            />
          )}
        </Grid>
        {isActiveSelectIcon ? (
          <SelectFile
            setFile={setFile}
            style={{ bottom: 60, left: 10, borderRadius: "15px" }}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default NewUserModel;
