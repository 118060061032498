import React, { useEffect, useState } from "react";
import "./home.css";
import { NavLink } from "react-router-dom";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Video } from "../../Components/Video";
import TQUIZIMG from "../../Assets/eil/gallery.gif";
import POLLSIMG from "../../Assets/eil/discussion.gif";
import CONTESTSIMG from "../../Assets/eil/engagement.gif";
import LEADERBOARDIMG from "../../Assets/eil/games.gif";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import PinnedNotifications from "../../Components/PinnedNotifications";
import Header from "../../Components/Header";
import { OC_ID, SITE_URL } from "../../config";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Home = () => {
  let token = localStorage.getItem("token");

  let sessionStorageCurrentUserAvatarUrl = JSON.parse(
    localStorage.getItem("currentUserAvatarUrl")
  );
  let sessionStorageCurrentUserId = localStorage.getItem("currentUserId");
  const [isProfileImageModel, setIsProfileImageModel] = useState();
  const [imageFile, setImageFile] = useState();
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);

  useEffect(() => {
    if (sessionStorageCurrentUserAvatarUrl) {
      setIsProfileImageModel(false);
    }
    if (!sessionStorageCurrentUserAvatarUrl) {
      setIsProfileImageModel(true);
    }
  }, []);
  const handleClose = () => {
    setIsProfileImageModel(false);
  };

  const handleUpdateImageChange = (e) => {
    console.log(e.target.files[0]);
    setImageFile(e.target.files[0]);
  };

  const onSaveChanges = () => {
    let formdata = new FormData();
    if (imageFile) {
      formdata.append("user[avatar]", imageFile);
    }
    try {
      setSaveButtonLoading(true);
      fetch(
        `${SITE_URL}/api/version/v1/app-users/${sessionStorageCurrentUserId}?occasion_id=${OC_ID}`,
        {
          method: "PUT",
          body: formdata,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          return console.log(res), res.json();
        })
        .then((result) => {
          if (result) {
            handleClose();
            setSaveButtonLoading(false);
            setImageFile();
          }
          if (result?.data?.attributes?.avatarUrl) {
            return localStorage.setItem(
              "currentUserAvatarUrl",
              JSON.stringify(result?.data?.attributes?.avatarUrl)
            );
          }
        });
    } catch {
      console.log("error");
    }
  };

  return (
    <>
      <Grid
        container
        style={{
          marginBottom: "60px",
          background: "#ffffff",
          display: "flex",
          flexDirection: "column",
          maxWidth: "500px",
          margin: "auto",
          paddingBottom: "50px",
        }}
      >
        <Header />
        <Grid
          style={{
            width: "100%",
            padding: "20px 10px",
            textAlign: "center",
          }}
        >
          <Video />
        </Grid>

        <PinnedNotifications />

        <Grid
          style={{
            width: "100%",
            maxWidth: "500px",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 10px",
            gap: 10,
            marginTop: 15,
          }}
        >
          <Typography
            style={{
              color: "rgb(0,96,170)",
              fontSize: 18,
              fontWeight: "800",
              textAlign: "center",
            }}
          >
            Take a photo for your conference pics
          </Typography>
          <a
            href="https://t.ly/7sJ-b"
            target="_blank"
            style={{
              width: 150,
              backgroundColor: "rgb(0,96,170)",
              color: "#ffffff",
              padding: "10px 12px",
              border: "none",
              borderRadius: 10,
              cursor: "pointer",
              textDecoration: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            AI Gallery
          </a>
        </Grid>
        <div className="rout_container">
          <Typography
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: 16,
              fontWeight: "bold",
              color: "rgba(255, 255, 255, 0.9)",
              marginBottom: 20,
            }}
          >
            EIL Technical Conclave 2024
          </Typography>
          <Grid
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <NavLink className="routnavbar" to="/agenda">
              <img
                src={TQUIZIMG}
                alt="TQUIZIMG"
                style={{ width: 50, objectFit: "contain" }}
              />
              <Typography
                variant="body1"
                style={{
                  fontWeight: "700",
                  fontSize: 15,
                  fontFamily: '"Roboto Condensed", sans-serif;',
                }}
              >
                Agenda
              </Typography>
            </NavLink>
            <a
              className="routnavbar"
              href="https://maps.app.goo.gl/ESwRKui37XTjrVcZ9"
              target="_blank"
            >
              <img
                src={LEADERBOARDIMG}
                alt="LEADERBOARDIMG"
                style={{ width: 50, objectFit: "contain" }}
              />
              <Typography
                variant="body1"
                style={{
                  fontWeight: "700",
                  fontSize: 15,
                  fontFamily: '"Roboto Condensed", sans-serif;',
                }}
              >
                Event Location
              </Typography>
            </a>
          </Grid>
          <Grid
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <NavLink className="routnavbar" to="/information">
              <img
                src={CONTESTSIMG}
                alt="CONTESTSIMG"
                style={{ width: 50, objectFit: "contain" }}
              />
              <Typography
                variant="body1"
                style={{
                  fontWeight: "700",
                  fontSize: 15,
                  fontFamily: '"Roboto Condensed", sans-serif;',
                }}
              >
                Information
              </Typography>
            </NavLink>

            <NavLink className="routnavbar" to="/helpdesk">
              <img
                src={POLLSIMG}
                alt="POLLSIMG"
                style={{ width: 50, objectFit: "contain" }}
              />
              <Typography
                variant="body1"
                style={{
                  fontWeight: "700",
                  fontSize: 15,
                  fontFamily: '"Roboto Condensed", sans-serif;',
                }}
              >
                Helpline
              </Typography>
            </NavLink>
          </Grid>
        </div>
      </Grid>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isProfileImageModel}
      >
        <DialogActions
          style={{
            minWidth: "100%",
            height: "300px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid style={{ width: "100%", textAlign: "center" }}>
            <label
              style={{ fontSize: "20px", fontWeight: "600", color: "#221c46" }}
            >
              Upload Profile Picture
            </label>
            <input
              style={{
                width: "100%",
                margin: "25px 0 25px 25%",
                cursor: "pointer",
                textAlign: "center",
              }}
              accept="image/*"
              id="raised-button-file"
              placeholder="Select Image"
              type="file"
              onChange={handleUpdateImageChange}
            />
            {saveButtonLoading ? (
              <Button
                disabled
                style={{ border: "1px solid #221c46", color: "#221c46" }}
              >
                Loading...
              </Button>
            ) : (
              <Grid style={{ width: "100%" }}>
                <Button
                  style={{
                    padding: "8px 12px",
                    border: "1px solid #221c46",
                    cursor: "pointer",
                    margin: "0 10px",

                    fontSize: "12px",
                    backgroundColor: "rgb(0,96,170)",
                    color: "#ffffff",
                    fontWeight: "600",
                  }}
                  onClick={onSaveChanges}
                >
                  Save changes
                </Button>
              </Grid>
            )}
            <Grid style={{ width: "100%" }}>
              <Button
                style={{
                  padding: "8px 12px",
                  border: "1px solid #221c46",
                  cursor: "pointer",
                  margin: "20px 10px",

                  fontSize: "12px",
                  backgroundColor: "rgb(0,96,170)",
                  color: "#ffffff",
                  fontWeight: "600",
                }}
                onClick={handleClose}
              >
                Update Later
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default Home;
