import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { Grid, Button, Snackbar, Alert, Typography } from "@mui/material";

import TIMELINELOGO from "../../Assets/pluxeenew/logo.png";
import LEFT_ARROW from "../../Assets/AgendaIcon.png";
import ChatroomCard from "./components/ChatroomCard";
import { union } from "lodash";
import createCable from "../../Services/ActionCableService";
import SearchBar from "../../Components/SearchBar";
import ChatUserCard from "./components/ChatUserCard";
import MessageModel from "./MessageModel";
import RejectUserModel from "./components/RejectUserModel";
import NewUserModel from "./components/NewUserModel";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { OC_ID, SITE_URL } from "../../config";
import Header from "../../Components/Header";

const VibeChat = () => {
  let token = localStorage.getItem("token");
  const navigate = useNavigate();
  const allusercontainer = useRef(null);
  const [snackbarData, setSnackbarData] = React.useState({
    message: "",
    type: "",
  });
  const [chatroomData, setChatroomData] = useState([]);
  const [allUserData, setAllUserData] = useState({
    data: [],
    links: {},
  });
  const [chatroomId, setChatroomId] = useState(null);
  const [rejectChatroomId, setRejectChatroomId] = useState(null);
  const [otherUsername, setOtherUsername] = useState(null);
  const [otherUserUrl, setOtherUserUrl] = useState(null);
  const [otherUserid, setOtherUserid] = useState(null);
  const [otherUserEmail, setOtherUserEmail] = useState(null);
  const [otherUserMobile, setOtherUserMobile] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [isNewUserModel, setIsNewUserModel] = useState(false);
  const [text, setText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isMediaModel, setIsMediaModel] = useState(false);
  const [conversationCreatedUserId, setConversationCreatedUserId] =
    useState(null);
  const [isConversationAccepted, setisConversationAccepted] = useState(null);
  const [showProfile, setshowProfile] = useState(false);
  const [isRejectUserModel, setisRejectUserModel] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [currentChatTabs, setcurrentChatTabs] = useState(0);
  const [scrollData, setScrollData] = useState({
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0,
  });

  const fetchChatrooms = async () => {
    try {
      const response = await fetch(
        `${SITE_URL}/api/version/v2/oc/${OC_ID}/visitant/chatroom/messaging-rooms`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status == 200 || response.status == 201) {
        const data = await response.json();
        setChatroomData(data.data);
      } else {
        setChatroomData([]);
        setSnackbarData({
          message: "Error in Chatroom server",
          type: "error",
        });
      }
    } catch (error) {
      setChatroomData([]);
      console.error(error);
    }
  };

  const fetchAllowedChatUsers = async (page = 1, searchQuery) => {
    try {
      let query = `page=${page}`;
      if (!!searchQuery) {
        query += `&name_or_email_or_mobile=${searchQuery}`;
      }
      const response = await fetch(
        `${SITE_URL}/api/version/v2/oc/${OC_ID}/visitant/fetch-permitted-chat-users?${query}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status == 200 || response.status == 201) {
        const result = await response.json();
        if (result?.data?.length) {
          const { data, links } = result;
          let NewData = allUserData?.data
            ?.concat(data)
            .filter(
              (obj, index, array) =>
                array.findIndex((el) => el.id === obj.id) === index
            );
          let NewLinks = links;

          if (page !== 1) {
            setAllUserData((pre) => ({
              data: [...NewData],
              links: NewLinks,
            }));
          } else {
            setAllUserData({
              data: [...data],
              links: NewLinks,
            });
          }
        }
      } else {
        setAllUserData({ data: [], links: {} });
        setSnackbarData({
          message: "Error in AllUserData server",
          type: "error",
        });
      }
    } catch (error) {
      setAllUserData({ data: [], links: {} });
      console.error(error);
    }
  };

  useEffect(() => {
    fetchChatrooms();
    chatRooms();
    fetchAllowedChatUsers();

    return () => {
      unmountChatRooms();
    };
  }, []);

  const getNextPage = (str) => {
    const str1 = str?.split("=");
    const res2 = str1[1]?.split("&");
    return parseInt(res2[0], 10);
  };

  const handleEndReach = async () => {
    if (allUserData?.links?.next) {
      const page = getNextPage(allUserData?.links?.next);
      if (page > 1) {
        await fetchAllowedChatUsers(page, searchQuery);
      }
    }
  };

  const onScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = allusercontainer.current;
    setScrollData({
      scrollTop,
      scrollHeight,
      clientHeight,
    });

    // If we're near the bottom of the scroll container and there's more data to load
    if (
      scrollHeight - scrollTop - clientHeight < 50 &&
      allUserData?.links?.next
    ) {
      handleEndReach();
    }
  }, [allUserData?.links?.next]);

  const debounceForEndReach = (func, time = 200) => {
    let timer;
    return (...rest) => {
      timer && clearTimeout(timer);
      timer = setTimeout(() => {
        func(...rest);
      }, time);
    };
  };

  const debouncedOnScroll = useMemo(() => {
    return debounceForEndReach(onScroll, 200); // Adjust debounce time as needed
  }, [onScroll]);

  const chatRooms = async () => {
    const CableApp = await createCable();
    const subscription = CableApp.cable.subscriptions.create(
      "ChatroomsChannel",
      {
        connected() {
          console.log("Connected to chatroom channel");
        },
        disconnected() {
          console.log("Disconnected from chatroom channel");
        },
        received: (data) => {
          if (Object.keys(data).length > 0) {
            fetchChatrooms();
          }
        },
      }
    );
    if (subscription) {
      setSubscription(subscription);
    }
  };

  const unmountChatRooms = async () => {
    if (subscription) {
      try {
        subscription.unsubscribe();
        console.log("chatroom channel unsubscribed.....");
      } catch (error) {
        console.error("Error during disconnection:", error);
      }
      setSubscription(null);
    }
  };

  const onModalClosed = () => {
    setChatroomId(null);
    setCurrentIndex(null);
    setOtherUsername(null);
    setOtherUserid(null);
    setOtherUserUrl(null);
    fetchChatrooms();
    chatRooms();
  };

  const handleChatroomPress = (
    chatroomId,
    otherUsername,
    otherUserUrl,
    otherUserid,
    conversationCreatedUserId,
    isConversationAccepted,
    otherUserEmail,
    otherUserMobile
  ) => {
    setChatroomId(chatroomId);
    setOtherUsername(otherUsername);
    setOtherUserUrl(otherUserUrl);
    setOtherUserid(otherUserid);
    setConversationCreatedUserId(conversationCreatedUserId);
    setisConversationAccepted(isConversationAccepted);
    setOtherUserEmail(otherUserEmail);
    setOtherUserMobile(otherUserMobile);
  };

  const handleChatUserPress = (activeChatUserData) => {
    let filterIsActiveChatroomUser = chatroomData?.find(
      (el) => el.attributes?.otherUserData?.id == activeChatUserData.id
    );
    if (!!filterIsActiveChatroomUser) {
      if (Object.keys(filterIsActiveChatroomUser).length > 0) {
        setChatroomId(filterIsActiveChatroomUser.id);
        setOtherUsername(
          filterIsActiveChatroomUser?.attributes?.otherUserData.name
        );
        setOtherUserUrl(
          filterIsActiveChatroomUser?.attributes?.otherUserData.avatarUrl
        );
        setOtherUserid(
          filterIsActiveChatroomUser?.attributes?.otherUserData.id
        );
        setOtherUserEmail(
          filterIsActiveChatroomUser?.attributes?.otherUserData.email
        );
        setOtherUserMobile(
          filterIsActiveChatroomUser?.attributes?.otherUserData.mobile
        );
        setConversationCreatedUserId(
          filterIsActiveChatroomUser?.attributes?.createdById
        );
        setisConversationAccepted(
          filterIsActiveChatroomUser?.attributes?.isConversationAccepted
        );
      }
    } else {
      setIsNewUserModel(true);
      setOtherUsername(activeChatUserData?.attributes?.name);
      setOtherUserUrl(activeChatUserData?.attributes?.avatarUrl);
      setOtherUserid(activeChatUserData?.id);
      setOtherUserEmail(activeChatUserData?.attributes?.email);
      setOtherUserMobile(activeChatUserData?.attributes?.mobile);
      closeModal();
    }
  };

  const debounce = (fn, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        fn(...args);
      }, delay);
    };
  };

  const fetchSearchResults = async (query) => {
    try {
      setLoading(true);
      await fetchAllowedChatUsers(1, query);
    } catch (error) {
      console.log("search Error", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = debounce(fetchSearchResults, 200);

  const closeModal = () => {
    setModalActive(false);
    setSearchQuery("");
  };

  const closeNewUserModel = () => {
    setIsNewUserModel(false);
    setOtherUsername(null);
    setOtherUserUrl(null);
    setOtherUserid(null);
  };

  const openProfileModal = () => {
    setshowProfile(true);
  };
  const closeRejectUserModel = () => {
    setisRejectUserModel(false);
    setRejectChatroomId(null);
  };

  const openRejectUserModal = (chatRMId) => {
    setisRejectUserModel(true);
    setRejectChatroomId(chatRMId);
  };

  const updateChatroom = async () => {
    const formData = new FormData();
    formData.append("chatroom[is_conversation_accepted]", "false");
    const response = await fetch(
      `${SITE_URL}/api/version/v2/oc/${OC_ID}/visitant/chatroom/messaging-rooms/${rejectChatroomId}/agree-to-chatroom-conversation`,
      {
        method: "PUT",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status == 200 || response.status == 201) {
      fetchChatrooms();
      closeRejectUserModel();
    }
  };

  const onclicked = () => {
    navigate("/");
  };

  const handleClose = () => {
    setSnackbarData({
      message: "",
      type: "",
    });
  };

  return (
    <>
      <Grid container style={{ overscrollBehaviorX: "hidden" }}>
        {/* <Grid
          style={{
            width: "100%",
            height: "15vh",
            background: "rgb(0,96,170)",
            padding: "10px 25px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid style={{ width: "80px" }}>
            <img style={{ width: "100%" }} src={TIMELINELOGO} alt="Logo" />
          </Grid>
        </Grid> */}
        <Header />
        <Grid
          style={{
            width: "100%",
            height: "5vh",
            backgroundColor: "white",
          }}
        >
          <Grid
            style={{
              width: "100%",
              height: "100%",
              // padding: "5px 20px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Button
              onClick={onclicked}
              style={{
                // padding: "5px 10px",
                textTransform: "none",
                background: "none",
                color: "#ffffff",
                fontSize: "15px",
                fontWeight: "500",
                fontFamily: "'Roboto Condensed', sans-serif",
              }}
            >
              <ArrowBackRoundedIcon
                style={{ color: "#221C46", marginRight: 10 }}
              />
              <b style={{ color: "#221C46" }}>Networking</b>
            </Button>
          </Grid>
        </Grid>
        <Grid className="chatroom_container">
          {!chatroomId ? (
            <Grid className="chatroom_innercontainer">
              <Grid
                style={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  borderWidth: 1,
                  borderColor: "rgb(0,96,170)",
                  backgroundColor: "rgb(0,96,170)",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <button
                  style={{
                    width: "50%",
                    paddingLeft: 6,
                    paddingRight: 6,
                    paddingTop: 15,
                    paddingBottom: 15,
                    color: "#ffffff",
                    cursor: "pointer",
                    border: "none",
                    borderBottom:
                      currentChatTabs == 0 ? "2px solid #ffffff" : "none",
                    background: "transparent",
                    borderRadius: "5px 0px 0px 0px",
                  }}
                  onClick={() => setcurrentChatTabs(0)}
                >
                  All
                </button>
                <button
                  style={{
                    width: "50%",
                    paddingLeft: 6,
                    paddingRight: 6,
                    paddingTop: 15,
                    paddingBottom: 15,
                    color: "#ffffff",
                    cursor: "pointer",
                    border: "none",
                    borderBottom:
                      currentChatTabs == 1 ? "2px solid #ffffff" : "none",
                    borderRadius: "0px 5px 0px 0px",
                    background: "transparent",
                  }}
                  onClick={() => setcurrentChatTabs(1)}
                >
                  My Chats
                </button>
              </Grid>
              <Grid
                sx={{
                  width: "100%",
                  overflowY: currentChatTabs === 0 ? "hidden" : "auto",
                  border: "5px solid rgb(0,96,170)",
                  borderRadius: "0px 0px 5px 5px",
                  background: "rgb(0,96,170)",
                }}
              >
                {currentChatTabs === 0 ? (
                  <Grid style={{ width: "100%", height: "100%" }}>
                    <SearchBar
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                      onSearch={handleSearch}
                      placeholder="Search User"
                    />
                    {allUserData.data && allUserData.data?.length > 0 ? (
                      <Grid
                        ref={allusercontainer}
                        onScroll={debouncedOnScroll}
                        style={{
                          width: "100%",
                          height: "60vh",
                          overflow: "auto",
                          padding: "5px 10px 70px 10px",
                          background: "rgb(0,96,170)",
                        }}
                      >
                        {allUserData?.data?.map((item) => (
                          <ChatUserCard
                            key={item?.id}
                            usersData={item}
                            onPress={handleChatUserPress}
                          />
                        ))}
                      </Grid>
                    ) : (
                      <Typography
                        variant="caption"
                        style={{ textAlign: "center", color: "#ffffff" }}
                      >
                        No data is there !!!
                      </Typography>
                    )}
                  </Grid>
                ) : null}
                {currentChatTabs === 1 ? (
                  chatroomData && chatroomData?.length > 0 ? (
                    <Grid
                      sx={{
                        width: "100%",
                        height: "58vh",
                        overflow: "auto",
                        padding: "5px 10px 70px 10px",
                        background: "rgb(0,96,170)",
                      }}
                    >
                      {chatroomData?.map((item) => (
                        <ChatroomCard
                          key={item?.id}
                          chatroom={item}
                          onPress={handleChatroomPress}
                          onPressForRejectModel={openRejectUserModal}
                        />
                      ))}
                    </Grid>
                  ) : (
                    <Typography
                      variant="caption"
                      style={{ textAlign: "center", color: "#ffffff" }}
                    >
                      No data is there
                    </Typography>
                  )
                ) : null}
              </Grid>
            </Grid>
          ) : (
            <Grid className="chatroom_innercontainer">
              <MessageModel
                chatroomId={chatroomId}
                otherUsername={otherUsername}
                otherUserUrl={otherUserUrl}
                onModalClosed={onModalClosed}
                occasionId="146"
                otherUserid={otherUserid}
                conversationCreatedUserId={conversationCreatedUserId}
                isConversationAccepted={isConversationAccepted}
                otherUserEmail={otherUserEmail}
                otherUserMobile={otherUserMobile}
                token={token}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      {isRejectUserModel ? (
        <RejectUserModel
          closeRejectUserModel={closeRejectUserModel}
          updateChatroom={updateChatroom}
        />
      ) : null}

      {isNewUserModel && (
        <NewUserModel
          closeNewUserModel={closeNewUserModel}
          openProfileModal={openProfileModal}
          otherUserUrl={otherUserUrl}
          otherUsername={otherUsername}
          otherUserid={otherUserid}
          setIsMediaModel={setIsMediaModel}
          fetchChatrooms={fetchChatrooms}
          token={token}
        />
      )}
      <Snackbar
        open={!!snackbarData.message}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
        style={{ border: "2px solid black", borderRadius: 5 }}
        message={snackbarData.message}
      >
        <Alert severity={snackbarData.type}>{snackbarData.message}</Alert>
      </Snackbar>
    </>
  );
};

export default VibeChat;
