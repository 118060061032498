import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import CloseIcon from "@mui/icons-material/Close";

const SelectFile = ({ setFile, style }) => {
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [selectedFileFile, setSelectedFileFile] = useState(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const url = URL.createObjectURL(file);
      setSelectedImageUrl(url);
    }
  };
  const handleVideoChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setFile(file);
      const url = URL.createObjectURL(file);
      setSelectedVideoUrl(url);
    }
  };
  const handleDocumentChange = (e) => {
    setSelectedFileFile(e.target.files[0]);
    setFile(e.target.files[0]);
  };

  const handleRemoveFile = () => {
    setSelectedImageUrl(null);
    setSelectedVideoUrl(null);
    setSelectedFileFile(null);
    setFile(null);
  };

  return (
    <Grid
      style={{
        position: "absolute",
        bottom: 50,
        left: 0,
        width: "280px",
        height: "auto",
        backgroundColor: "#000000",
        borderRadius: "0px 25px 0px 0px",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        ...style,
      }}
    >
      {selectedImageUrl || selectedVideoUrl || selectedFileFile ? (
        <>
          {selectedImageUrl && (
            <Grid
              style={{
                width: "100px",
                height: "100px",
                marginLeft: "auto",
                marginRight: "auto",
                position: "relative",
              }}
            >
              <img
                src={selectedImageUrl}
                alt="selected image"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
              <CloseIcon
                style={{
                  background: "#5C2C5C",
                  color: "#ffffff",
                  fontSize: 20,
                  position: "absolute",
                  top: 0,
                  right: 3,
                  borderRadius: 25,
                  padding: 3,
                  cursor: "pointer",
                }}
                onClick={() => handleRemoveFile()}
              />
            </Grid>
          )}
          {selectedVideoUrl && (
            <Grid
              style={{
                width: "100px",
                height: "100px",
                marginLeft: "auto",
                marginRight: "auto",
                position: "relative",
              }}
            >
              <video
                src={selectedVideoUrl}
                alt="selected video"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                controls
              />
              <CloseIcon
                style={{
                  background: "#5C2C5C",
                  color: "#ffffff",
                  fontSize: 20,
                  position: "absolute",
                  top: 0,
                  right: 3,
                  borderRadius: 25,
                  padding: 3,
                  cursor: "pointer",
                }}
                onClick={() => handleRemoveFile()}
              />
            </Grid>
          )}
          {selectedFileFile && (
            <Grid
              style={{
                width: "100px",
                height: "100px",
                marginLeft: "auto",
                marginRight: "auto",
                position: "relative",
              }}
            >
              <FilePresentIcon
                style={{
                  fontSize: 100,
                  color: "red",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              <CloseIcon
                style={{
                  background: "#5C2C5C",
                  color: "#ffffff",
                  fontSize: 20,
                  position: "absolute",
                  top: 0,
                  right: 3,
                  borderRadius: 25,
                  padding: 3,
                  cursor: "pointer",
                }}
                onClick={() => handleRemoveFile()}
              />
            </Grid>
          )}
        </>
      ) : (
        <>
          <input
            type="file"
            id="imageInput"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(e) => handleImageChange(e)}
          />
          <label
            htmlFor="imageInput"
            style={{
              color: "#ffffff",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 10,
              cursor: "pointer",
            }}
          >
            <InsertPhotoIcon style={{ color: "#ffffff", marginRight: 5 }} />{" "}
            <Typography variant="button">Select Image</Typography>
          </label>
          <input
            type="file"
            id="videoinput"
            accept="video/*"
            style={{ display: "none" }}
            onChange={(e) => handleVideoChange(e)}
          />
          <label
            htmlFor="videoinput"
            style={{
              color: "#ffffff",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 10,
              cursor: "pointer",
            }}
          >
            <VideoCameraBackIcon style={{ color: "#ffffff", marginRight: 5 }} />{" "}
            <Typography variant="button">Select Video</Typography>
          </label>
          <input
            type="file"
            id="documentinput"
            accept=".pdf,.doc,.docx,.txt,.xlsx"
            style={{ display: "none" }}
            onChange={(e) => handleDocumentChange(e)}
          />
          <label
            htmlFor="documentinput"
            style={{
              color: "#ffffff",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: 10,
              cursor: "pointer",
            }}
          >
            <FilePresentIcon style={{ color: "#ffffff", marginRight: 5 }} />{" "}
            <Typography variant="button">Select File</Typography>
          </label>
        </>
      )}
    </Grid>
  );
};

export default SelectFile;
