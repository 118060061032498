import { IconButton, SxProps } from "@mui/material";
import DownIcon from "@mui/icons-material/KeyboardArrowDown";
import UpIcon from "@mui/icons-material/KeyboardArrowUp";

export const ScrollToLatestButton = ({ onClick }) => {
  return (
    <IconButton
      size="small"
      onClick={onClick}
      sx={{
        backgroundColor: "red",
        color: "white",
        position: "absolute",
        right: "5px",
        bottom: "14%",
        zIndex: "4",
        "&:hover": {
          opacity: "1",
          backgroundColor: "red",
          color: "white",
          position: "absolute",
          right: "5px",
          bottom: "14%",
          zIndex: "4",
        },
      }}
    >
      <DownIcon
        sx={{
          color: "white",
        }}
      />
    </IconButton>
  );
};
